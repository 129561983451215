import styled from 'styled-components';
import {
  Paragraph,
  Text,
  Title,
  Link,
  SecondaryText,
  MessageLabel,
} from '@idaho-aeyc/ui-kit';

export const STitle = styled(Title)``;

export const SText = styled(Text)``;

export const SSecondaryText = styled(SecondaryText)``;

export const SLink = styled(Link)``;

export const SParagraph = styled(Paragraph)``;

export const SMessageLabel = styled(MessageLabel)``;
