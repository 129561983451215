import React, { FC } from 'react';
import { Counter, Row, Col } from '@idaho-aeyc/ui-kit';
import { useTranslation } from '../../../../hooks';
import { EI18nLanguageUI } from '../../../../data/types';
import { TEditChildcareItemProps } from '../EditChildcare.types';
import { SCeckbox } from '../../ProvidingChildcare.styles';

export const EditChildcareItem: FC<TEditChildcareItemProps> = ({
  childcareItem,
  onCheckboxChange,
  counterOnChange,
  translationLanguage = EI18nLanguageUI.ENGLISH,
}) => {
  const { t } = useTranslation({ lng: translationLanguage });
  return (
    <Row align="middle" justify="space-between">
      <Col>
        <SCeckbox onChange={onCheckboxChange} checked={childcareItem.checked}>
          {t('descriptions.input-labels.AGES')}{' '}
          {t(`enums.childcare-age-group.${childcareItem.name}`)}
        </SCeckbox>
      </Col>
      <Col>
        <Counter
          onChange={counterOnChange}
          disabled={!childcareItem.checked}
          defaultValue={childcareItem.value || 0}
          minValue={1}
          wrapperPadding="4px 16px"
        />
      </Col>
    </Row>
  );
};

export default EditChildcareItem;
