import { FormInstance, useForm as AntdForm } from 'antd/es/form/Form';

export type TFromReturnType<T> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setCustomFieldValue: (fieldName: string, customValue?: any) => void; // Todo: find solution
  form: FormInstance<T>;
};

export const useForm = <T,>(
  initialValues?: FormInstance<T>,
): TFromReturnType<T> => {
  const [form] = AntdForm<T>(initialValues);
  const { setFieldsValue } = form;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setCustomFieldValue = (fieldName: string, customValue?: any): void => {
    // @ts-ignore
    setFieldsValue({ [fieldName]: customValue }); // Todo : find solution
  };
  return { setCustomFieldValue, form };
};
