import { TRegistrantDB } from 'data/types/registrants.types';
import { ERegistrantsUrls } from 'data/urls/registrants.url';
import { authNoSessionSplitApi } from 'redux/helpers/slice.helpers';

const baseUrl = `${ERegistrantsUrls.BASE_URL}`;

export const registrantsInviteApi = authNoSessionSplitApi(
  'registrantsInvite',
  [],
).injectEndpoints({
  endpoints: build => ({
    getWorkshopRegistrationCancelValidation: build.query<
      TRegistrantDB[],
      {
        workshopId?: string;
        registrantId?: string;
        page?: number;
        pageSize?: number;
      }
    >({
      query({ workshopId, registrantId, page, pageSize }) {
        return {
          url: `${baseUrl}/${ERegistrantsUrls.REGISTRANTS}/external`,
          method: 'GET',
          params: {
            page,
            size: pageSize,
            ...(workshopId && { workshopId }),
            ...(registrantId && { registrantId }),
          },
        };
      },
      transformResponse: (data: { content: TRegistrantDB[] }) => {
        return data.content;
      },
    }),
    cancelWorkshopRegistration: build.mutation<
      void,
      { workshopId: string; registrantId: string }
    >({
      query({ workshopId, registrantId }) {
        return {
          url: `${baseUrl}/${ERegistrantsUrls.REGISTRANTS}/unregister/external`,
          method: 'PATCH',
          body: { workshopId, registrantId },
        };
      },
      onQueryStarted(arg, { queryFulfilled }) {
        queryFulfilled
          .then(data => {
            return data;
          })
          .catch(() => {
            // do nothing
          });
      },
    }),
  }),
});

export const {
  useGetWorkshopRegistrationCancelValidationQuery,
  useCancelWorkshopRegistrationMutation,
} = registrantsInviteApi;
