import { FC } from 'react';
import { useTranslation } from '@packages/utils';
import { Row, Col } from 'components/Base/Grid';
import { EWorkshopPartType } from 'data/types/workshops.types';
import { EAttendanceStatus } from 'data/types/registrants.types';
import { TAttendanceViewItemProps } from './AttendanceViewItem.types';
import AttendanceItemRow from '../AttendanceItemRow';

const orientationKitKey =
  'descriptions.admin-portal.workshops.details.workshop-registrants.orientation-kit';

const AttendanceViewItem: FC<TAttendanceViewItemProps> = ({
  attendanceStatus,
  kitCount,
  partType,
}) => {
  const { t } = useTranslation();
  const isStandAlone = partType === EWorkshopPartType.STANDALONE;
  const isElearning = attendanceStatus === EAttendanceStatus.E_LEARNING;

  if (isStandAlone) {
    return (
      <Row gutter={[0, 8]}>
        <Col span={24}>
          <AttendanceItemRow
            title={t(`${orientationKitKey}.label.ATTENDANCE`)}
            isChecked={attendanceStatus !== EAttendanceStatus.ABSENT}
            width={110}
            isElearning={isElearning}
          />
        </Col>
        <Col span={24}>
          <AttendanceItemRow
            title={t(`${orientationKitKey}.label.KITS`)}
            count={kitCount || 0}
            width={110}
            showOnlyCount
          />
        </Col>
      </Row>
    );
  }

  return (
    <Row gutter={[0, 8]} className="white-space-no-wrap mt-1">
      <AttendanceItemRow
        title={t(`enums.workshop-part-type.${partType}`)}
        isChecked={attendanceStatus !== EAttendanceStatus.ABSENT}
        count={kitCount || 0}
        width={50}
        isElearning={isElearning}
      />
    </Row>
  );
};

export default AttendanceViewItem;
