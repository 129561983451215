export const downloadCSV = (
  headers: string[],
  records: any[],
  outputtedRows: (record: any) => string[],
  filename = `records-${new Date().toISOString().split('T')[0]}.csv`,
) => {
  const rows = records
      .filter(row => row !== undefined)
      .map(record =>
          outputtedRows(record).map(value => (value === null || value === undefined || String(value).toLowerCase() === 'null' ? '' : value)
      )); // Replace null/undefined with an empty string


  let csvContent = `${headers.join(',')}\n`;
  rows.forEach(rowArray => {
    const row = rowArray.join(',');
    csvContent += `${row}\n`;
  });

  const encodedUri = `data:text/csv;charset=utf-8,${encodeURIComponent(
    csvContent,
  )}`;
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
