import { FC } from 'react';
import { useTranslation } from '@packages/utils';
import { useTheme } from 'styled-components';
import { Paragraph, Title } from 'components/Base/Typography';
import { EIconNames, Icon } from 'components/Base/Icon';
import { Tooltip } from 'components/Base/Tooltip';
import { Flex } from 'antd';
import { EmailOutline, PhoneOutline } from 'assets/icon/icon';
import useCopyToClipboard from 'hooks/useCopyClipboard';
import { TCoordinatorInfoProps } from './CoordinatorInfo.types';

const CoordinatorInfo: FC<TCoordinatorInfoProps> = ({
  display,
  email,
  phone,
  popoverTitle = '',
  children,
}) => {
  const theme = useTheme();
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.workshops.details.sessions.view.actions.update.facilitator.popover',
  });

  const [isCopied, copyToClipboard] = useCopyToClipboard();

  const handleCopyClick = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();

    if (!email) return;

    copyToClipboard(email);
  };

  return (
    <Tooltip
      arrow={false}
      color="#fff"
      title={
        display && (
          <Flex
            vertical
            gap={12}
            style={{
              width: '282px',
              borderRadius: `${theme.tooltipBorderRadius}px`,
              color: theme.colorBlackPrimary,
              padding: '16px',
            }}
          >
            <Title level={3} color={theme.colorBlackPrimary}>
              {popoverTitle}
            </Title>
            <Flex vertical gap={8}>
              <Flex gap={8} align="center">
                <EmailOutline />
                <Flex gap={4} align="center">
                  <Paragraph
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: '235px',
                    }}
                  >
                    {email || t('N/A')}
                  </Paragraph>
                  <button
                    type="button"
                    onClick={handleCopyClick}
                    style={{
                      background: 'none',
                      border: 'none',
                      padding: 0,
                      cursor: 'pointer',
                    }}
                    aria-label="Copy email"
                  >
                    <Icon
                      icon={
                        isCopied ? EIconNames.CHECK : EIconNames.COPY_OUTLINED
                      }
                      alt="copy"
                      size={12}
                      className="pointer"
                      color={theme.colorBlackIconColor}
                      tooltip={{ title: 'Copy' }}
                    />
                  </button>
                </Flex>
              </Flex>

              <Flex gap={8} align="center">
                <PhoneOutline />
                <Paragraph>{phone || t('N/A')}</Paragraph>
              </Flex>
            </Flex>
          </Flex>
        )
      }
    >
      {children}
    </Tooltip>
  );
};

export default CoordinatorInfo;
