import {
  ELCPlacesConverter,
  ELCPlacesFilterConverter,
  ElcMeetingAttendeesConverter,
  ElcMeetingNoteConverter,
  ElcMeetingNoteEditConverter,
  ElcMeetingTATimeConverter,
  ElcMeetingThingsToShareConverter,
  ElcMeetingThingsToShareEditConverter,
  ElcMeetingTopicsConverter,
  ElcResourcesConverter,
  PMEditConverter,
  addContactConverter,
  addELCPlacesConverter,
  addGuestAttendeeConverter,
  addMeetingConverter,
  addResourceConverter,
  boundariesCreateConverter,
  coLeadAgencyEditConverter,
  contactsConverter,
  contactsFilterConverter,
  createElcActionItemConvertor,
  createElcMeetingNoteConvertor,
  createElcMeetingTATimeConvertor,
  createElcMeetingTopicConvertor,
  deleteBoundariesConverter,
  deleteContactConverter,
  deleteELCPlaceConverter,
  deleteElcMeetingNoteConverter,
  deleteElcMeetingTATimeConverter,
  deleteElcMeetingTopicConverter,
  deleteGuestAttendeeConverter,
  deleteMeetingConverter,
  deleteNoteConverter,
  deleteResourceConverter,
  editElcMeetingActionItemCommonConverter,
  editElcMeetingActionItemDueDateConverter,
  editElcMeetingActionItemOwnerConverter,
  editElcMeetingActionItemStatusConverter,
  editElcMeetingActionItemSummaryConverter,
  editMeetingConverter,
  elcConverter,
  elcDetailConverter,
  fiscalAgentEditConverter,
  leadAgencyEditConverter,
  leadEditConverter,
  meetingsActionItemConverter,
  meetingsConverter,
  myElcConverter,
  noteCreateConverter,
  noteEditConverter,
  topicContextEditConverter,
  topicProgressEditConverter,
  topicRoadBlockEditConverter,
  updateAttendanceStatusConverter,
  updateELACMemberConverter,
  uploaderConverter,
} from 'data/convertors/elcs.converters';
import {
  exportCsvConverter,
  pagableDataConverter,
} from 'data/convertors/general.converters';
import { getOrganizationsIdentityConvertor } from 'data/convertors/organizations.convertors';
import { userIdentityGetConverter } from 'data/convertors/user.convertors';
import {
  EContactsColumnKey,
  TAddContactUI,
  TUpdateELACMemberUI,
  TBoundariesCreateUI,
  TBoundariesDeleteUI,
  TCoLeadAgencyEditUI,
  TContactsDB,
  TContactsUI,
  TDeleteContactUI,
  TElcDetailDB,
  TElcDetailUI,
  TElcItemDB,
  TElcItemUI,
  TFiscalAgentEditUI,
  TLeadAgencyEditUI,
  TLeadEditUI,
  TMyElcItemDB,
  TMyElcItemUI,
  TNoteCreateUI,
  TNoteDeleteUI,
  TNoteEditUI,
  TPMEditUI,
  TAddELCPlaceUI,
  TDeleteELCPlaceUI,
  TELCPlacesUI,
  TELCPlacesDB,
  EELCPlacesColumnKey,
  TMeetingsUI,
  TMeetingsDB,
  TDeleteMeetingUI,
  TAddMeetingUI,
  TEditMeetingUI,
  TCreateElcMeetingTopicUI,
  TElcMeetingTopicDB,
  TElcMeetingTopicUI,
  TContextEditUI,
  TProgressEditUI,
  TRoadBlockEditUI,
  TDeleteElcMeetingTopicUI,
  TElcMeetingNoteUI,
  TElcMeetingNoteDB,
  TCreateElcMeetingNoteUI,
  TElcMeetingNoteEditUI,
  TDeleteElcMeetingNoteUI,
  TElcMeetingAttendeesUI,
  TElcMeetingAttendeesDB,
  TUpdateAttendanceStatusUI,
  TAddGuestAttendeeUI,
  TDeleteGuestAttendeeUI,
  TElcMeetingTATimeUI,
  TElcMeetingTATimeDB,
  TCreateElcMeetingTATimeUI,
  TDeleteElcMeetingTATimeUI,
  TElcMeetingActionItemsUI,
  TElcMeetingActionItemsDB,
  TAddElcMeetingsActionItemUI,
  TDeleteElcMeetingActionItemUI,
  TEditElcMeetingActionItemSummaryUI,
  TEditElcMeetingActionItemOwnerUI,
  TEditElcMeetingActionItemDueDateUI,
  TEditElcMeetingActionItemStatusUI,
  TUploadResourceDB,
  TUploadResourceUI,
  TElcResourcesUI,
  TElcResourcesDB,
  TAddResourceUI,
  TDeleteResourceUI,
  TElcMeetingThingToShareEditUI,
  TElcMeetingThingsToShareUI,
  TElcMeetingThingsToShareDB,
} from 'data/types/elcs.types';
import {
  TExportCsvDB,
  TExportCsvUI,
  TExportFilters,
  TPageableDataWithContentDB,
  TPageableDataWithContentUI,
  TTableFilters,
} from 'data/types/generic.types';
import {
  TOrganizationsIdentityDB,
  TOrganizationsIdentityUI,
} from 'data/types/organizations.types';
import { TUserIdentityDB, TUserIdentityUI } from 'data/types/user.types';
import { EElcsUrls } from 'data/urls/elcs.url';
import { authSplitApi } from 'redux/helpers/slice.helpers';
import { downloadFile } from 'redux/services/fileServices';
import { workshopsApi } from './workshops.slice';

const baseUrl = `${EElcsUrls.ELCS}`;

export const elcsApi = authSplitApi('elcs', [
  'elcs',
  'elcs-contacts',
  'elc-notes-categories',
  'contacts-identity',
  'elac-contacts-identity',
  'elcs-contacts',
  'elc-places-identity',
  'elcs-meetings',
  'elc-meeting-topics',
  'elc-meeting-notes',
  'elc-meeting-attendees',
  'elc-meeting-ta-time',
  'elcs-meetings-action-items',
  'elc-meeting-resources',
  'elc-meeting-things-to-share',
]).injectEndpoints({
  endpoints: build => ({
    getElcs: build.query<TElcItemUI[], void>({
      query() {
        return {
          url: `${baseUrl}`,
          method: 'GET',
        };
      },
      providesTags: ['elcs'],
      transformResponse: (data: TElcItemDB[]) => {
        return data?.length ? data.map(item => elcConverter.fromDb(item)) : [];
      },
    }),
    getMyElcs: build.query<TMyElcItemUI[], void>({
      query() {
        return {
          url: `${baseUrl}/${EElcsUrls.MYELCS}`,
          method: 'GET',
        };
      },
      providesTags: ['elcs'],
      transformResponse: (data: TMyElcItemDB[]) => {
        return data?.length
          ? data.map(item => myElcConverter.fromDb(item))
          : [];
      },
    }),
    getAllContacts: build.query<
      TPageableDataWithContentUI<TContactsUI[]>,
      {
        elcId: string;
      }
    >({
      query({ elcId }) {
        return {
          url: `${baseUrl}/${EElcsUrls.CONTACTS}`,
          method: 'GET',
          params: {
            elcId,
          },
        };
      },
      providesTags: ['elcs-contacts'],
    }),
    getElcById: build.query<TElcDetailUI, { elcId: string }>({
      query({ elcId }) {
        return {
          url: `${baseUrl}/${elcId}`,
          method: 'GET',
        };
      },
      providesTags: ['elc'],
      transformResponse: (data: TElcDetailDB) => {
        return elcDetailConverter.fromDb(data || {});
      },
    }),
    getElcNotesAvailableCategories: build.query<string[], { elcId: string }>({
      query({ elcId }) {
        return {
          url: `${baseUrl}/${EElcsUrls.NOTES}/${EElcsUrls.AVAILABLE_CATEGORIES}`,
          method: 'GET',
          params: { elcId },
        };
      },
      providesTags: ['elc-notes-categories'],
    }),
    editLead: build.mutation<void, TLeadEditUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EElcsUrls.LEAD}`,
          method: 'PATCH',
          body: leadEditConverter.toDb(body),
        };
      },
      invalidatesTags: ['elcs'],
    }),
    editFirstPM: build.mutation<void, TPMEditUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EElcsUrls.FIRST_PROJECT_MANAGER}`,
          method: 'PATCH',
          body: PMEditConverter.toDb(body),
        };
      },
      invalidatesTags: ['elcs'],
    }),
    editSecondPM: build.mutation<void, TPMEditUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EElcsUrls.SECOND_PROJECT_MANAGER}`,
          method: 'PATCH',
          body: PMEditConverter.toDb(body),
        };
      },
      invalidatesTags: ['elcs'],
    }),
    editLeadAgency: build.mutation<void, TLeadAgencyEditUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EElcsUrls.LEAD_AGENCY}`,
          method: 'PATCH',
          body: leadAgencyEditConverter.toDb(body),
        };
      },
      invalidatesTags: ['elc'],
    }),
    editCoLeadAgency: build.mutation<void, TCoLeadAgencyEditUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EElcsUrls.CO_LEAD_AGENCY}`,
          method: 'PATCH',
          body: coLeadAgencyEditConverter.toDb(body),
        };
      },
      invalidatesTags: ['elc'],
    }),
    editFiscalAgent: build.mutation<void, TFiscalAgentEditUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EElcsUrls.FISCAL_AGENT}`,
          method: 'PATCH',
          body: fiscalAgentEditConverter.toDb(body),
        };
      },
      invalidatesTags: ['elc'],
    }),
    editNote: build.mutation<void, TNoteEditUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EElcsUrls.NOTES}`,
          method: 'PATCH',
          body: noteEditConverter.toDb(body),
        };
      },
      invalidatesTags: ['elc', 'elc-notes-categories'],
    }),
    createNote: build.mutation<string, TNoteCreateUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EElcsUrls.NOTES}`,
          method: 'POST',
          body: noteCreateConverter.toDb(body),
        };
      },
      invalidatesTags: ['elc', 'elc-notes-categories'],
    }),
    deleteNote: build.mutation<void, TNoteDeleteUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EElcsUrls.NOTES}`,
          method: 'DELETE',
          body: deleteNoteConverter.toDb(body),
        };
      },
      invalidatesTags: ['elc', 'elc-notes-categories'],
    }),
    createZipCode: build.mutation<string, TBoundariesCreateUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EElcsUrls.BOUNDARIES}`,
          method: 'POST',
          body: boundariesCreateConverter.toDb(body),
        };
      },
      invalidatesTags: ['elc'],
      onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            dispatch(workshopsApi.util.invalidateTags(['workshops']));
          })
          .catch(() => {
            // do nothing
          });
      },
    }),
    deleteZipCode: build.mutation<void, TBoundariesDeleteUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EElcsUrls.BOUNDARIES}`,
          method: 'DELETE',
          body: deleteBoundariesConverter.toDb(body),
        };
      },
      invalidatesTags: ['elc'],
      onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            dispatch(workshopsApi.util.invalidateTags(['workshops']));
          })
          .catch(() => {
            // do nothing
          });
      },
    }),
    addContact: build.mutation<string, TAddContactUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EElcsUrls.CONTACTS}`,
          method: 'POST',
          body: addContactConverter.toDb(body),
        };
      },
      invalidatesTags: [
        'elcs-contacts',
        'contacts-identity',
        'elac-contacts-identity',
      ],
    }),
    updateELACMember: build.mutation<string, TUpdateELACMemberUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EElcsUrls.CONTACTS}/${EElcsUrls.ELAC_MEMBER}`,
          method: 'PATCH',
          body: updateELACMemberConverter.toDb(body),
        };
      },
      invalidatesTags: ['elcs-contacts', 'elac-contacts-identity'],
    }),
    deleteContact: build.mutation<void, TDeleteContactUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EElcsUrls.CONTACTS}`,
          method: 'DELETE',
          body: deleteContactConverter.toDb(body),
        };
      },
      invalidatesTags: ['elcs-contacts'],
    }),
    exportContactsCSV: build.query<
      TExportCsvUI,
      TExportFilters<EContactsColumnKey> & { elcId: string }
    >({
      query({ filters, elcId }) {
        return {
          url: `${baseUrl}/${EElcsUrls.CONTACTS}/${EElcsUrls.EXPORT}`,
          method: 'GET',
          params: {
            ...(filters && {
              ...contactsFilterConverter(filters),
            }),
            elcId,
          },
        };
      },
      transformResponse: (data: TExportCsvDB) => {
        const convertedData = exportCsvConverter('text/csv').fromDb(data);
        downloadFile(convertedData.content, convertedData.fileName);
        return convertedData;
      },
    }),
    exportElacMemberCSV: build.query<
      TExportCsvUI,
      TExportFilters<EContactsColumnKey> & { elcId: string }
    >({
      query({ filters, elcId }) {
        return {
          url: `${baseUrl}/${EElcsUrls.CONTACTS}/${EElcsUrls.EXPORT}`,
          method: 'GET',
          params: {
            ...(filters && {
              ...contactsFilterConverter(filters),
            }),
            elcId,
            isElacMember: true,
          },
        };
      },
      transformResponse: (data: TExportCsvDB) => {
        const convertedData = exportCsvConverter('text/csv').fromDb(data);
        downloadFile(convertedData.content, convertedData.fileName);
        return convertedData;
      },
    }),
    getContactsIdentity: build.query<TUserIdentityUI[], { elcId: string }>({
      query({ elcId }) {
        return {
          url: `${baseUrl}/${EElcsUrls.CONTACTS}/${EElcsUrls.USER_IDENTITY}`,
          method: 'GET',
          params: { elcId },
        };
      },
      transformResponse: (data: TUserIdentityDB[]) => {
        return data?.length
          ? data.map(item => userIdentityGetConverter.fromDb(item))
          : [];
      },
      providesTags: ['contacts-identity'],
    }),
    getELACContactsIdentity: build.query<TUserIdentityUI[], { elcId: string }>({
      query({ elcId }) {
        return {
          url: `${baseUrl}/${EElcsUrls.CONTACTS}/${EElcsUrls.IDENTITY}`,
          method: 'GET',
          params: { elcId },
        };
      },
      transformResponse: (data: TUserIdentityDB[]) => {
        return data?.length
          ? data.map(item => userIdentityGetConverter.fromDb(item))
          : [];
      },
      providesTags: ['elac-contacts-identity'],
    }),
    getContacts: build.query<
      TPageableDataWithContentUI<TContactsUI[]>,
      TTableFilters<EContactsColumnKey> & {
        elcId?: string;
        isElacMember?: boolean;
      }
    >({
      query({
        page,
        pageSize,
        sortColumn: { sortDirection, sortField },
        filters,
        elcId,
        isElacMember,
      }) {
        return {
          url: `${baseUrl}/${EElcsUrls.CONTACTS}`,
          method: 'GET',
          params: {
            page,
            size: pageSize,
            ...(sortField && sortDirection && { sortField, sortDirection }),
            ...(filters && {
              ...contactsFilterConverter(filters),
            }),
            elcId,
            isElacMember,
          },
        };
      },
      providesTags: ['elcs-contacts'],
      transformResponse: (data: TPageableDataWithContentDB<TContactsDB[]>) => {
        return {
          ...pagableDataConverter.fromDb(data),
          content: (data.content || []).map(parent =>
            contactsConverter.fromDb(parent),
          ),
        };
      },
    }),
    getELCPlacesIdentity: build.query<
      TOrganizationsIdentityUI[],
      { elcId: string }
    >({
      query({ elcId }) {
        return {
          url: `${baseUrl}/${EElcsUrls.PLACES}/${EElcsUrls.IDENTITY}`,
          method: 'GET',
          params: { elcId },
        };
      },
      transformResponse: (data: TOrganizationsIdentityDB[]) => {
        return data?.length
          ? data.map(item => getOrganizationsIdentityConvertor.fromDb(item))
          : [];
      },
      providesTags: ['elc-places-identity'],
    }),
    addELCPlace: build.mutation<string, TAddELCPlaceUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EElcsUrls.PLACES}`,
          method: 'POST',
          body: addELCPlacesConverter.toDb(body),
        };
      },
      invalidatesTags: ['elc-places', 'elc-places-identity'],
    }),
    getELCPlaces: build.query<
      TPageableDataWithContentUI<TELCPlacesUI[]>,
      TTableFilters<EELCPlacesColumnKey> & {
        elcId?: string;
      }
    >({
      query({
        page,
        pageSize,
        sortColumn: { sortDirection, sortField },
        filters,
        elcId,
      }) {
        return {
          url: `${baseUrl}/${EElcsUrls.PLACES}`,
          method: 'GET',
          params: {
            page,
            size: pageSize,
            ...(sortField && sortDirection && { sortField, sortDirection }),
            ...(filters && {
              ...ELCPlacesFilterConverter(filters),
            }),
            elcId,
          },
        };
      },
      providesTags: ['elc-places'],
      transformResponse: (data: TPageableDataWithContentDB<TELCPlacesDB[]>) => {
        return {
          ...pagableDataConverter.fromDb(data),
          content: (data.content || []).map(place =>
            ELCPlacesConverter.fromDb(place),
          ),
        };
      },
    }),
    deleteELCPlace: build.mutation<void, TDeleteELCPlaceUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EElcsUrls.PLACES}`,
          method: 'DELETE',
          body: deleteELCPlaceConverter.toDb(body),
        };
      },
      invalidatesTags: ['elc-places'],
    }),
    getMeetings: build.query<
      TPageableDataWithContentUI<TMeetingsUI[]>,
      TTableFilters<EContactsColumnKey> & {
        elcId?: string;
        isElacMember?: boolean;
      }
    >({
      query({
        page,
        pageSize,
        sortColumn: { sortDirection, sortField },
        elcId,
        isElacMember,
      }) {
        return {
          url: `${baseUrl}/${EElcsUrls.MEETINGS}`,
          method: 'GET',
          params: {
            page,
            size: pageSize,
            ...(sortField && sortDirection && { sortField, sortDirection }),
            elcId,
            isElacMember,
          },
        };
      },
      providesTags: ['elcs-meetings'],
      transformResponse: (data: TPageableDataWithContentDB<TMeetingsDB[]>) => {
        return {
          ...pagableDataConverter.fromDb(data),
          content: (data.content || []).map(parent =>
            meetingsConverter.fromDb(parent),
          ),
        };
      },
    }),
    deleteMeeting: build.mutation<void, TDeleteMeetingUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EElcsUrls.MEETINGS}`,
          method: 'DELETE',
          body: deleteMeetingConverter.toDb(body),
        };
      },
      invalidatesTags: ['elcs-meetings'],
    }),
    addMeeting: build.mutation<string, TAddMeetingUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EElcsUrls.MEETINGS}`,
          method: 'POST',
          body: addMeetingConverter.toDb(body),
        };
      },
      invalidatesTags: ['elcs-meetings'],
    }),
    editMeeting: build.mutation<string, TEditMeetingUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EElcsUrls.MEETINGS}`,
          method: 'PUT',
          body: editMeetingConverter.toDb(body),
        };
      },
      invalidatesTags: ['elcs-meetings'],
    }),
    getElcMeetingTopic: build.query<
      TElcMeetingTopicUI[],
      { meetingId: string }
    >({
      query({ meetingId }) {
        return {
          url: `${baseUrl}/${EElcsUrls.MEETINGS}/${EElcsUrls.TOPICS}`,
          method: 'GET',
          params: { meetingId },
        };
      },
      providesTags: ['elc-meeting-topics'],
      transformResponse: (data: TElcMeetingTopicDB[]) => {
        return data?.length
          ? data.map(item => ElcMeetingTopicsConverter.fromDb(item))
          : [];
      },
    }),
    createElcMeetingTopic: build.mutation<string, TCreateElcMeetingTopicUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EElcsUrls.MEETINGS}/${EElcsUrls.TOPICS}`,
          method: 'POST',
          body: createElcMeetingTopicConvertor.toDb(body),
        };
      },
      invalidatesTags: ['elc-meeting-topics', 'elcs-meetings'],
    }),
    editElcMeetingTopicContext: build.mutation<void, TContextEditUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EElcsUrls.MEETINGS}/${EElcsUrls.TOPICS}/${EElcsUrls.CONTEXT}`,
          method: 'PATCH',
          body: topicContextEditConverter.toDb(body),
        };
      },
      invalidatesTags: ['elc-meeting-topics', 'elcs-meetings'],
    }),
    editElcMeetingTopicProgress: build.mutation<void, TProgressEditUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EElcsUrls.MEETINGS}/${EElcsUrls.TOPICS}/${EElcsUrls.PROGRESS}`,
          method: 'PATCH',
          body: topicProgressEditConverter.toDb(body),
        };
      },
      invalidatesTags: ['elc-meeting-topics', 'elcs-meetings'],
    }),
    editElcMeetingTopicRoadBlock: build.mutation<void, TRoadBlockEditUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EElcsUrls.MEETINGS}/${EElcsUrls.TOPICS}/${EElcsUrls.ROAD_BLOCK}`,
          method: 'PATCH',
          body: topicRoadBlockEditConverter.toDb(body),
        };
      },
      invalidatesTags: ['elc-meeting-topics', 'elcs-meetings'],
    }),
    deleteElcMeetingTopic: build.mutation<void, TDeleteElcMeetingTopicUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EElcsUrls.MEETINGS}/${EElcsUrls.TOPICS}`,
          method: 'DELETE',
          body: deleteElcMeetingTopicConverter.toDb(body),
        };
      },
      invalidatesTags: ['elc-meeting-topics', 'elcs-meetings'],
    }),
    getElcMeetingNotes: build.query<TElcMeetingNoteUI[], { meetingId: string }>(
      {
        query({ meetingId }) {
          return {
            url: `${baseUrl}/${EElcsUrls.MEETINGS}/${EElcsUrls.NOTES}`,
            method: 'GET',
            params: { meetingId },
          };
        },
        providesTags: ['elc-meeting-notes'],
        transformResponse: (data: TElcMeetingNoteDB[]) => {
          return data?.length
            ? data.map(item => ElcMeetingNoteConverter.fromDb(item))
            : [];
        },
      },
    ),
    createElcMeetingNote: build.mutation<string, TCreateElcMeetingNoteUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EElcsUrls.MEETINGS}/${EElcsUrls.NOTES}`,
          method: 'POST',
          body: createElcMeetingNoteConvertor.toDb(body),
        };
      },
      invalidatesTags: ['elc-meeting-notes', 'elcs-meetings'],
    }),
    editElcMeetingNote: build.mutation<void, TElcMeetingNoteEditUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EElcsUrls.MEETINGS}/${EElcsUrls.NOTES}`,
          method: 'PATCH',
          body: ElcMeetingNoteEditConverter.toDb(body),
        };
      },
      invalidatesTags: ['elc-meeting-notes', 'elcs-meetings'],
    }),
    deleteElcMeetingNote: build.mutation<void, TDeleteElcMeetingNoteUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EElcsUrls.MEETINGS}/${EElcsUrls.NOTES}`,
          method: 'DELETE',
          body: deleteElcMeetingNoteConverter.toDb(body),
        };
      },
      invalidatesTags: ['elc-meeting-notes', 'elcs-meetings'],
    }),
    getElcMeetingAttendees: build.query<
      TElcMeetingAttendeesUI,
      { meetingId: string }
    >({
      query({ meetingId }) {
        return {
          url: `${baseUrl}/${EElcsUrls.MEETINGS}/${EElcsUrls.ATTENDEES}`,
          method: 'GET',
          params: { meetingId },
        };
      },
      providesTags: ['elc-meeting-attendees'],
      transformResponse: (data: TElcMeetingAttendeesDB) => {
        return ElcMeetingAttendeesConverter.fromDb(data);
      },
    }),
    updateAttendanceStatus: build.mutation<void, TUpdateAttendanceStatusUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EElcsUrls.MEETINGS}/${EElcsUrls.ATTENDEES}/${EElcsUrls.ATTENDANCE_STATUS}`,
          method: 'PATCH',
          body: updateAttendanceStatusConverter.toDb(body),
        };
      },
      invalidatesTags: ['elc-meeting-attendees', 'elcs-meetings'],
    }),
    addGuestAttendee: build.mutation<void, TAddGuestAttendeeUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EElcsUrls.MEETINGS}/${EElcsUrls.ATTENDEES}`,
          method: 'POST',
          body: addGuestAttendeeConverter.toDb(body),
        };
      },
      invalidatesTags: ['elc-meeting-attendees', 'elcs-meetings'],
    }),
    deleteGuestAttendee: build.mutation<void, TDeleteGuestAttendeeUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EElcsUrls.MEETINGS}/${EElcsUrls.ATTENDEES}`,
          method: 'DELETE',
          body: deleteGuestAttendeeConverter.toDb(body),
        };
      },
      invalidatesTags: ['elc-meeting-attendees', 'elcs-meetings'],
    }),
    getElcMeetingTATime: build.query<
      TElcMeetingTATimeUI[],
      { meetingId: string }
    >({
      query({ meetingId }) {
        return {
          url: `${baseUrl}/${EElcsUrls.MEETINGS}/${EElcsUrls.TA_TIMES}`,
          method: 'GET',
          params: { meetingId },
        };
      },
      providesTags: ['elc-meeting-ta-time'],
      transformResponse: (data: TElcMeetingTATimeDB[]) => {
        return data?.length
          ? data.map(item => ElcMeetingTATimeConverter.fromDb(item))
          : [];
      },
    }),
    createElcMeetingTATime: build.mutation<string, TCreateElcMeetingTATimeUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EElcsUrls.MEETINGS}/${EElcsUrls.TA_TIMES}`,
          method: 'POST',
          body: createElcMeetingTATimeConvertor.toDb(body),
        };
      },
      invalidatesTags: ['elc-meeting-ta-time', 'elcs-meetings'],
    }),
    deleteElcMeetingTATime: build.mutation<void, TDeleteElcMeetingTATimeUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EElcsUrls.MEETINGS}/${EElcsUrls.TA_TIMES}`,
          method: 'DELETE',
          body: deleteElcMeetingTATimeConverter.toDb(body),
        };
      },
      invalidatesTags: ['elc-meeting-ta-time', 'elcs-meetings'],
    }),
    getElcMeetingActionItem: build.query<
      TPageableDataWithContentUI<TElcMeetingActionItemsUI[]>,
      Pick<TTableFilters, 'page' | 'pageSize'> & {
        meetingId: string;
      }
    >({
      query({ page, pageSize, meetingId }) {
        return {
          url: `${baseUrl}/${EElcsUrls.MEETINGS}/${EElcsUrls.ACTION_ITEMS}`,
          method: 'GET',
          params: {
            page,
            size: pageSize,
            ...(meetingId && { meetingId }),
          },
        };
      },
      providesTags: ['elcs-meetings-action-items'],
      transformResponse: (
        data: TPageableDataWithContentDB<TElcMeetingActionItemsDB[]>,
      ) => {
        return {
          ...pagableDataConverter.fromDb(data),
          content: (data.content || []).map(actionItem =>
            meetingsActionItemConverter.fromDb(actionItem),
          ),
        };
      },
    }),
    createElcMeetingActionItem: build.mutation<
      void,
      TAddElcMeetingsActionItemUI
    >({
      query(body) {
        return {
          url: `${baseUrl}/${EElcsUrls.MEETINGS}/${EElcsUrls.ACTION_ITEMS}`,
          method: 'POST',
          body: createElcActionItemConvertor.toDb(body),
        };
      },
      invalidatesTags: ['elcs-meetings-action-items', 'elcs-meetings'],
    }),
    deleteElcMeetingActionItem: build.mutation<
      void,
      TDeleteElcMeetingActionItemUI
    >({
      query(body) {
        return {
          url: `${baseUrl}/${EElcsUrls.MEETINGS}/${EElcsUrls.ACTION_ITEMS}`,
          method: 'DELETE',
          body: editElcMeetingActionItemCommonConverter.toDb(body),
        };
      },
      invalidatesTags: ['elcs-meetings-action-items', 'elcs-meetings'],
    }),
    editElcMeetingActionItemSummary: build.mutation<
      void,
      TEditElcMeetingActionItemSummaryUI
    >({
      query(body) {
        return {
          url: `${baseUrl}/${EElcsUrls.MEETINGS}/${EElcsUrls.ACTION_ITEMS}/summary`,
          method: 'PATCH',
          body: editElcMeetingActionItemSummaryConverter.toDb(body),
        };
      },
      invalidatesTags: ['elcs-meetings-action-items', 'elcs-meetings'],
    }),
    editElcMeetingActionItemOwner: build.mutation<
      void,
      TEditElcMeetingActionItemOwnerUI
    >({
      query(body) {
        return {
          url: `${baseUrl}/${EElcsUrls.MEETINGS}/${EElcsUrls.ACTION_ITEMS}/owner`,
          method: 'PATCH',
          body: editElcMeetingActionItemOwnerConverter.toDb(body),
        };
      },
      invalidatesTags: ['elcs-meetings-action-items', 'elcs-meetings'],
    }),
    editElcMeetingActionItemDueDate: build.mutation<
      void,
      TEditElcMeetingActionItemDueDateUI
    >({
      query(body) {
        return {
          url: `${baseUrl}/${EElcsUrls.MEETINGS}/${EElcsUrls.ACTION_ITEMS}/due-date`,
          method: 'PATCH',
          body: editElcMeetingActionItemDueDateConverter.toDb(body),
        };
      },
      invalidatesTags: ['elcs-meetings-action-items', 'elcs-meetings'],
    }),
    editElcMeetingActionItemStatus: build.mutation<
      void,
      TEditElcMeetingActionItemStatusUI
    >({
      query(body) {
        return {
          url: `${baseUrl}/${EElcsUrls.MEETINGS}/${EElcsUrls.ACTION_ITEMS}/is-completed`,
          method: 'PATCH',
          body: editElcMeetingActionItemStatusConverter.toDb(body),
        };
      },
      invalidatesTags: ['elcs-meetings-action-items', 'elcs-meetings'],
    }),
    uploadResources: build.mutation<TUploadResourceDB, TUploadResourceUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EElcsUrls.STORAGE}/${EElcsUrls.RESOURCES}`,
          method: 'POST',
          body: uploaderConverter.toDb(body),
        };
      },
    }),
    getElcResources: build.query<TElcResourcesUI, { elcId: string }>({
      query({ elcId }) {
        return {
          url: `${baseUrl}/${EElcsUrls.RESOURCES}`,
          method: 'GET',
          params: { elcId },
        };
      },
      providesTags: ['elc-meeting-resources'],
      transformResponse: (data: TElcResourcesDB) => {
        return ElcResourcesConverter.fromDb(data);
      },
    }),
    addResources: build.mutation<string, TAddResourceUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EElcsUrls.RESOURCES}`,
          method: 'POST',
          body: addResourceConverter.toDb(body),
        };
      },
      invalidatesTags: ['elc-meeting-resources'],
    }),
    deleteResource: build.mutation<void, TDeleteResourceUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EElcsUrls.RESOURCES}`,
          method: 'DELETE',
          body: deleteResourceConverter.toDb(body),
        };
      },
      invalidatesTags: ['elc-meeting-resources'],
    }),
    getElcMeetingThingsToShare: build.query<
      TElcMeetingThingsToShareUI,
      { meetingId: string }
    >({
      query({ meetingId }) {
        return {
          url: `${baseUrl}/${EElcsUrls.MEETINGS}/${EElcsUrls.THINGS_TO_SHARE}`,
          method: 'GET',
          params: { meetingId },
        };
      },
      providesTags: ['elc-meeting-things-to-share'],
      transformResponse: (data: TElcMeetingThingsToShareDB) =>
        ElcMeetingThingsToShareConverter.fromDb(data),
    }),
    editElcMeetingThingsToShareUpdate: build.mutation<
      void,
      TElcMeetingThingToShareEditUI
    >({
      query(body) {
        return {
          url: `${baseUrl}/${EElcsUrls.MEETINGS}/${EElcsUrls.THINGS_TO_SHARE}/${EElcsUrls.CONTENT}`,
          method: 'PATCH',
          body: ElcMeetingThingsToShareEditConverter.toDb(body),
        };
      },
      invalidatesTags: ['elc-meeting-things-to-share', 'elcs-meetings'],
    }),
  }),
});

export const {
  useGetElcsQuery,
  useEditLeadMutation,
  useGetAllContactsQuery,
  useEditFirstPMMutation,
  useEditSecondPMMutation,
  useEditLeadAgencyMutation,
  useEditCoLeadAgencyMutation,
  useEditFiscalAgentMutation,
  useEditNoteMutation,
  useCreateNoteMutation,
  useDeleteNoteMutation,
  useGetMyElcsQuery,
  useGetElcByIdQuery,
  useGetElcNotesAvailableCategoriesQuery,
  useCreateZipCodeMutation,
  useDeleteZipCodeMutation,
  useGetContactsQuery,
  useAddContactMutation,
  useUpdateELACMemberMutation,
  useDeleteContactMutation,
  useLazyExportContactsCSVQuery,
  useLazyExportElacMemberCSVQuery,
  useGetContactsIdentityQuery,
  useGetELACContactsIdentityQuery,
  useGetELCPlacesIdentityQuery,
  useAddELCPlaceMutation,
  useGetELCPlacesQuery,
  useDeleteELCPlaceMutation,
  useGetMeetingsQuery,
  useDeleteMeetingMutation,
  useAddMeetingMutation,
  useEditMeetingMutation,
  useGetElcMeetingTopicQuery,
  useCreateElcMeetingTopicMutation,
  useEditElcMeetingTopicContextMutation,
  useEditElcMeetingTopicProgressMutation,
  useEditElcMeetingTopicRoadBlockMutation,
  useDeleteElcMeetingTopicMutation,
  useGetElcMeetingNotesQuery,
  useCreateElcMeetingNoteMutation,
  useEditElcMeetingNoteMutation,
  useDeleteElcMeetingNoteMutation,
  useGetElcMeetingAttendeesQuery,
  useUpdateAttendanceStatusMutation,
  useAddGuestAttendeeMutation,
  useDeleteGuestAttendeeMutation,
  useGetElcMeetingTATimeQuery,
  useCreateElcMeetingTATimeMutation,
  useDeleteElcMeetingTATimeMutation,
  useGetElcMeetingActionItemQuery,
  useCreateElcMeetingActionItemMutation,
  useDeleteElcMeetingActionItemMutation,
  useEditElcMeetingActionItemSummaryMutation,
  useEditElcMeetingActionItemOwnerMutation,
  useEditElcMeetingActionItemDueDateMutation,
  useEditElcMeetingActionItemStatusMutation,
  useUploadResourcesMutation,
  useGetElcResourcesQuery,
  useAddResourcesMutation,
  useDeleteResourceMutation,
  useGetElcMeetingThingsToShareQuery,
  useEditElcMeetingThingsToShareUpdateMutation,
} = elcsApi;
