import React, { forwardRef } from 'react';

import { InputRef } from 'antd/es/input';
import { TextAreaRef } from 'antd/es/input/TextArea';
import { SInput, SPassword, STextArea, SPhoneInput } from './Input.styles';
import { TInputProps, TPasswordProps, TTextAreaProps } from './Input.types';

export const Input = forwardRef<InputRef, TInputProps>(
  (props: TInputProps, ref) => {
    return <SInput ref={ref} {...props} />;
  },
);

export const PasswordInput = (props: TPasswordProps) => {
  return <SPassword {...props} />;
};

export const TextArea = forwardRef<TextAreaRef, TTextAreaProps>(
  (props, ref) => {
    return <STextArea ref={ref} {...props} />;
  },
);

export const PhoneInput = (props: TInputProps) => {
  return <SPhoneInput {...props} />;
};
