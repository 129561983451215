import { FC } from 'react';
import { Tag } from 'antd';
import { TWorkshopSiteCoordinatorUI } from 'data/types/workshops.types';
import CoordinatorInfo from '../../CoordinatorInfo';

type CoordinatorSelectorProps = {
  coordinators?: TWorkshopSiteCoordinatorUI[];
  value?: string;
  label: string;
  closable?: boolean;
  onClose?: () => void;
};

const TagStyle = {
  borderColor: '#C8DCDE',
  background: '#E9F7F7',
  fontSize: '16px',
  fontFamily: 'Lato',
  padding: '2.5px 8px',
  marginRight: '8px',
};

const CoordinatorTag: FC<CoordinatorSelectorProps> = ({
  coordinators,
  value,
  label,
  closable,
  onClose,
}) => {
  const coordinator = coordinators?.find(option => option.id === value);

  return (
    <CoordinatorInfo
      display={!!coordinator?.id}
      popoverTitle={coordinator?.fullName}
      email={coordinator?.email}
      phone={coordinator?.phone}
    >
      <div>
        <Tag closable={closable} onClose={onClose} style={{ ...TagStyle }}>
          {label}
        </Tag>
      </div>
    </CoordinatorInfo>
  );
};

export default CoordinatorTag;
