import React, { FC } from 'react';
import { Row, Col, Text } from '@idaho-aeyc/ui-kit';
import { useTheme } from 'styled-components';
import { useTranslation } from '../../../hooks/useTranslation';
import { SColumnRow } from '../ProvidingChildcare.styles';
import { TChildcareViewProps } from './ChildcareView.types';

export const ChildcareView: FC<TChildcareViewProps> = ({ childcareItems }) => {
  const { t } = useTranslation({
    keyPrefix: 'enums.childcare-age-group',
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const theme: any = useTheme();
  if (!childcareItems.length) {
    return null;
  }
  return (
    <SColumnRow gutter={[0, 8]} wrap={false}>
      {childcareItems.map(childcareItem => {
        return (
          <Col>
            <Row gutter={[8, 0]} wrap={false}>
              <Col>
                <Text
                  fontWeight={theme.fontWeightSemiBold}
                  fontSize={theme.fontSizeLg}
                  color={theme.colorPrimaryDefault}
                >
                  {t(childcareItem.name)}:
                </Text>
              </Col>
              <Col>
                <Text fontWeight={theme.fontWeightRegular}>
                  {childcareItem.value || 0}
                </Text>
              </Col>
            </Row>
          </Col>
        );
      })}
    </SColumnRow>
  );
};
