import { useEffect, useState } from 'react';
import { Tooltip } from 'components/Base/Tooltip';
import { styled, useTheme } from 'styled-components';
import { EIconNames, Icon } from '../Icon';

const EmptyPlaceholder = styled.div`
  color: #808080;
`;

export const CopyTextButton = ({
  title = '',
  className = '',
  textHidden = false,
  textToCopy,
}: {
  title: string | undefined;
  className?: string;
  textHidden?: boolean;
  textToCopy?: string;
}) => {
  const [tooltipLabel, setTooltipLabel] = useState<string>('Copy');
  const theme = useTheme();

  useEffect(() => {
    if (tooltipLabel === 'Copied') {
      setTimeout(() => {
        setTooltipLabel('Copy');
      }, 3000);
    }
  }, [tooltipLabel]);

  if (!title && textHidden) return null;

  if (!title) return <EmptyPlaceholder>N/A</EmptyPlaceholder>;

  return (
    <div className={className}>
      {!textHidden && title}
      <button
        type="button"
        className="button-transparent ml-1"
        onClick={() => {
          navigator.clipboard.writeText(textToCopy || title);
          setTooltipLabel('Copied');
        }}
      >
        <Tooltip title={tooltipLabel}>
          <Icon
            icon={EIconNames.COPY_OUTLINED}
            size={15}
            color={theme.colorBlackPrimary}
          />
        </Tooltip>
        {/* Text */}
      </button>
    </div>
  );
};
