import { FC, useEffect, useState } from 'react';
import { EWorkshopDetailsAllowedActions } from 'data/types/workshops.types';
import { Col, Row } from 'components/Base/Grid';
import { TActionsGroupProps } from './Actions.types';
import PublishAction from './PublishAction';
import ActionsGroupButton from './ActionsGroupButton';
import RescheduleAction from './RescheduleAction';
import RepublishAction from './RepublishAction';

const Actions: FC<TActionsGroupProps> = ({ id, data }) => {
  const checkSessionDates = () => {
    let sessionComplete = false;

    data?.parts?.forEach(part => {
      if (!part.dateTime?.value) sessionComplete = true;
    });

    return sessionComplete;
  };

  const noSiteCoordinators = data?.siteCoordinators?.length === 0;
  const incompleteSessionDates = checkSessionDates();
  const noPhoto = !data?.photo.value;
  const noDescription = !data?.description?.value;
  const noClasses = !data?.classes.value?.length;

  const [isPublishDisabled, setIsPublishDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (
      noSiteCoordinators ||
      incompleteSessionDates ||
      noPhoto ||
      noDescription ||
      noClasses
    ) {
      setIsPublishDisabled(true);
    } else {
      setIsPublishDisabled(false);
    }
  }, [data]);

  return (
    <Row gutter={[16, 16]}>
      {data?.allowedActions?.includes(
        EWorkshopDetailsAllowedActions.PUBLISH,
      ) && (
        <Col>
          <PublishAction id={id} disabled={isPublishDisabled} />
        </Col>
      )}
      {data?.allowedActions?.includes(
        EWorkshopDetailsAllowedActions.RESCHEDULE,
      ) && (
        <Col>
          <RescheduleAction id={id} />
        </Col>
      )}
      {data?.allowedActions?.includes(
        EWorkshopDetailsAllowedActions.REPUBLISH,
      ) && (
        <Col>
          <RepublishAction id={id} />
        </Col>
      )}
      <Col>
        <ActionsGroupButton id={id} data={data} />
      </Col>
    </Row>
  );
};

export default Actions;
