import { RefObject, useEffect } from 'react';

export const useOutsideClick = (
  ref: RefObject<Element>,
  onOutsideClick: () => void,
  conditionToTrigger: boolean,
) => {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        if (conditionToTrigger) {
          onOutsideClick();
        }
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, onOutsideClick]);
};
