export enum EI18nLanguageUI {
  ENGLISH = 'en',
  SPANISH = 'es',
}

export enum EWorkshopChildcareAgeGroups {
  ONE_TO_TWO = 'ONE_TO_TWO',
  THREE_TO_FIVE = 'THREE_TO_FIVE',
  SIX_TO_TWELVE = 'SIX_TO_TWELVE',
}
