import { EIconNames } from '@idaho-aeyc/ui-kit';
import { FC } from 'react';

export type PublicRouteType = {
  component: FC<unknown>;
  path: string;
};

export type PrivateRouteType = {
  component: FC;
  path: string;
  show: boolean;
  showByPermissions?: boolean;
  icon?: EIconNames;
  selectedIcon?: EIconNames;
  title?: string;
  subRoutes?: PrivateRouteType[];
};

export type SubRouteType = Omit<PrivateRouteType, 'selectedIcon' | 'icon'>;

export enum RoutesPaths {
  LOGIN = 'login',
  RESET_PASSWORD = 'reset-password',
  WORKSHOP = 'workshop',
  FAMILY = 'family',
  SETTINGS = 'settings',
  TEAM = 'settings/team',
  LIGHT_BOX = 'settings/lightbox',
  SECURITY = 'settings/security',
  ORGANIZATION = 'organization',
  ORGANIZATION_DETAILS = 'organization/:id',
  PEOPLE = 'people',
  PEOPLE_DETAILS = 'people/:id',
  WORKSHOP_DETAILS = 'workshop/:id',
  WORKSHOP_CANCEL_REGISTRATION = 'workshop/:language/registation/cancel/:workshopId/:id',
  WORKSHOP_CANCEL_REGISTRATION_NOT_FOUND = 'workshop/en/registation/cancel/404',
  KIT_MANAGEMENT = 'kit-management',
  KIT_MANAGEMENT_WORKSHOP = 'kit-management/workshop',
  KIT_MANAGEMENT_PARENTS = 'kit-management/parents',
  PAGE_NOT_FOUND = '404',
  SUBSCRIBERS = 'subscribers',
  SUBSCRIBERS_NOTIFY = 'subscribers/notify',
  SUBSCRIBERS_WAIT_LIST = 'subscribers/wait-list',
  ELCS = 'elc',
  ELC_DETAIL = 'elc/:id',
  MYELCS = 'my-elc',
  MYELCS_DETAIL = 'my-elc/:id',
}
