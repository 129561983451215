export const getMaskEmail = (email: string) => {
  const [name, domain] = email.split('@');
  const maskedName = name.substring(0, 3) + '*'.repeat(name.length - 3);
  return `${maskedName}@${domain}`;
};

export const generateSpaceClassNames = (array: number[]) => {
  let classNames = ``;
  array.forEach((n, i) => {
    classNames += `
                .mx-${i} {
                    margin: 0 ${n}px !important;
                }
                .my-${i} {
                    margin: ${n}px 0 !important;
                }
                .m-${i} {
                    margin: ${n}px !important;
                }
                .mt-${i} {
                    margin-top: ${n}px !important;
                }
                .mb-${i} {
                    margin-bottom: ${n}px !important;
                }
                .ml-${i} {
                    margin-left: ${n}px !important;
                }
                .mr-${i} {
                    margin-right: ${n}px !important;
                }
                .pt-${i} {
                    padding-top: ${n}px !important;
                }
                .pb-${i} {
                    padding-bottom: ${n}px !important;
                }
                .pl-${i} {
                    padding-left: ${n}px !important;
                }
                .pr-${i} {
                    padding-right: ${n}px !important;
                }
                .px-${i} {
                    padding: 0 ${n}px !important;
                }
                .py-${i} {
                    padding: ${n}px 0 !important;
                }
                .p-${i} {
                    padding: ${n}px !important;
                }
            `;
  });

  return classNames;
};

export const filterTitleOption = (input: any, option: any) => {
  return ((option?.title ?? '') as string)
    .toLowerCase()
    .includes(input.toLowerCase());
};

export const filterLabelOption = (input: any, option: any) => {
  return ((option?.label ?? '') as string)
    .toLowerCase()
    .includes(input.toLowerCase());
};

export const getFileViewerUrl = (fileUrl: any) => {
  const extension = fileUrl.split('.').pop().toLowerCase();
  switch (extension) {
    case 'doc':
    case 'docx':
    case 'xls':
    case 'xlsx':
    case 'ppt':
    case 'pptx':
      return `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
        fileUrl,
      )}`;
    case 'pdf':
      return fileUrl; // Let the browser handle PDF files
    case 'png':
    case 'jpg':
    case 'jpeg':
      return fileUrl; // Let the browser handle image files
    default:
      return null;
  }
};
