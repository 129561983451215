import { useEffect, useState } from 'react';
import { useTranslation, Button } from '@packages/utils';
import PageWrapper from 'components/Base/PageWrapper';
import {
  ETableStoragePaths,
  TColumnFilterItem,
} from 'components/Base/Table/Table.types';
import useTable from 'components/Base/Table/hooks/useTable';
import { EIconNames } from 'components/Base/Icon';
import CardContent from 'components/Base/Layout/Content/CardContent';
import {
  EWorkshopSubscribersColumnKey,
  TWorkshopSubscriberDataUI,
} from 'data/types/subscribers.types.';
import {
  useGetWorkshopSubscribersCitiesQuery,
  useGetWorkshopSubscribersQuery,
} from 'redux/apiSlice/subscibers.slice';
import { ETagType } from 'data/types/tags.types';
import { useGetTagsListQuery } from 'redux/apiSlice/tags.slice';
import { Table } from 'components/Base/Table';
import { TRtkErrorType } from 'data/types/generic.types';
import { Alert } from '@idaho-aeyc/ui-kit';
import { Flex } from 'antd';
import useGetColumnsData from './WorkshopSubscribersList.data';
import CreateWorkshopSubscribersModal from './CreateWorkshopSubscribersModal';
import NotifyMeActions from './Actions/ExportAction';

const WorkshopSubscribersList = () => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.subscribers.list',
  });

  const [showAlert, setShowAlert] = useState(false);

  const { defaultColumns } = useGetColumnsData();
  const {
    page,
    pageSize,
    sortColumn,
    setPageSize,
    handleTableChange,
    selectedFilters,
    hasSelectedFilter,
    hasFilters,
    columns,
    setColumns,
    onFiltersReset,
    selectedRows,
    setSelectedRows,
  } = useTable<TWorkshopSubscriberDataUI>({
    storagePath: ETableStoragePaths.SUBSCRIBERS_NOTIFY_ME,
    defaultColumns,
  });
  const [open, setOpen] = useState(false);

  const { data, isFetching, isLoading, error } = useGetWorkshopSubscribersQuery(
    {
      page,
      pageSize,
      sortColumn,
      filters: selectedFilters,
    },
  );
  const { data: citiesFilters, isSuccess: citiesFiltersSuccess } =
    useGetWorkshopSubscribersCitiesQuery('filter');

  const { data: tagsFilters, isSuccess: tagsFiltersSuccess } =
    useGetTagsListQuery(ETagType.SUBSCRIBER);

  useEffect(() => {
    if (citiesFiltersSuccess || tagsFiltersSuccess) {
      setColumns(
        columns.map(item => {
          if (item.key === EWorkshopSubscribersColumnKey.CITY) {
            item.filters = citiesFilters as TColumnFilterItem[];
          }
          if (
            item.key === EWorkshopSubscribersColumnKey.TAG &&
            tagsFiltersSuccess
          ) {
            item.filters = tagsFilters;
            item.filterSearch = true;
          }

          return item;
        }),
      );
    }
  }, [citiesFilters, tagsFiltersSuccess]);

  const isEmpty = !data?.content.length && !hasSelectedFilter && !isFetching;

  return (
    <>
      <PageWrapper
        title={t('TITLE')}
        isLoading={isLoading}
        error={error as TRtkErrorType}
        emptyWrapper={{
          isEmpty,
          alt: 'Notification',
          icon: EIconNames.NOTIFICATION_FILLED,
          btnText: t('empty-view.BTN_TEXT'),
          emptyText: t('empty-view.EMPTY_TEXT'),
          onClick: () => setOpen(true),
        }}
        actions={
          <Flex gap={8}>
            <Button
              onClick={() => setOpen(true)}
              color="primary"
              type="primary"
            >
              {t('actions.add-subscriber.ADD_SUBSCRIBER_BTN')}
            </Button>
            <NotifyMeActions
              selectedRows={selectedRows}
              setShowAlert={setShowAlert}
            />
          </Flex>
        }
      >
        <CardContent>
          {showAlert && (
            <div className="mx-4" style={{ minHeight: '1px' }}>
              <Alert
                type="warning"
                closable
                message={t('table.ACTION_ALERT_MESSAGE')}
                className="mt-4"
                onClose={() => setShowAlert(false)}
              />
            </div>
          )}
          <Table<TWorkshopSubscriberDataUI>
            onFiltersReset={onFiltersReset}
            hasFilters={hasFilters}
            currentPage={page}
            columns={columns}
            dataSource={data?.content}
            loading={isFetching}
            pageSize={pageSize}
            setPageSize={setPageSize}
            totalDataCount={data?.totalElements}
            onChange={handleTableChange}
            storagePath={ETableStoragePaths.SUBSCRIBERS_NOTIFY_ME}
            rowKey={(record: TWorkshopSubscriberDataUI) =>
              record.workshopSubscriberId
            }
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          />
        </CardContent>
      </PageWrapper>
      {open && <CreateWorkshopSubscribersModal open={open} setOpen={setOpen} />}
    </>
  );
};

export default WorkshopSubscribersList;
