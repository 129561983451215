import styled from 'styled-components';
import { requiredAsteriskStyles } from '@idaho-aeyc/ui-kit';
import { Paragraph } from '../components';

export const SRequiredLabel = styled(Paragraph)`
  && {
    font-weight: ${({ theme }) => theme.fontWeightMedium} !important;
    ${requiredAsteriskStyles},
  }
`;
