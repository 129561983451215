import { EWorkshopChildcareAgeGroups } from '@packages/utils';
import {
  EAgeGroup,
  EWorkshopLanguage,
  TAddressShortDataDB,
  TAddressShortDataUI,
  TAddressTypesDB,
  TAddressTypesUI,
  TAllowedOperationsDB,
  TAllowedOperationsUI,
  TAllowedOperationsValueDB,
  TAllowedOperationsValueUI,
  TLanguageOptionUI,
  TShortAddressWithAllowOperationsDB,
  TShortAddressWithAllowOperationsUI,
} from './generic.types';
import { TTagDB, TTagUI } from './tags.types';

export enum EWorkshopStatus {
  PLANNING = 'PLANNING',
  PUBLISHED = 'PUBLISHED',
  CANCELLED = 'CANCELLED',
  CLOSED = 'CLOSED',
  RESCHEDULED = 'RESCHEDULED',
}

export enum EWorkshopCategory {
  STANDALONE = 'STANDALONE',
  SERIES_WITH_THREE_PARTS = 'SERIES_WITH_THREE_PARTS',
  SERIES_WITH_TWO_PARTS = 'SERIES_WITH_TWO_PARTS',
}

export enum EAttendanceType {
  IN_PERSON = 'IN_PERSON',
  VIRTUAL = 'VIRTUAL',
}

export enum EWorkshopPartType {
  STANDALONE = 'STANDALONE',
  PART_ONE = 'PART_ONE',
  PART_TWO = 'PART_TWO',
  PART_THREE = 'PART_THREE',
}

export enum EWorkshopStandaloneType {
  READY_FOR_MATH = 'READY_FOR_MATH',
  READY_FOR_KINDERGARTEN_SOCIAL_EMOTIONAL = 'READY_FOR_KINDERGARTEN_SOCIAL_EMOTIONAL',
  CHILD_CARE_AND_PRESCHOOL_PROGRAMS_PART_1 = 'CHILD_CARE_AND_PRESCHOOL_PROGRAMS_PART_1',
  CHILD_CARE_AND_PRESCHOOL_PROGRAMS_PART_2 = 'CHILD_CARE_AND_PRESCHOOL_PROGRAMS_PART_2',
}

export enum EWorkshopSeriesType {
  READY_FOR_KINDERGARTEN = 'READY_FOR_KINDERGARTEN',
  LEARNING_CHILD_CARE_AND_PRESCHOOL_PROGRAMS = 'LEARNING_CHILD_CARE_AND_PRESCHOOL_PROGRAMS',
}

export enum EWorkshopSortParams {
  CREATION_DATE = 'CREATION_DATE',
}
export enum EWorkshopFilterParams {
  STATUS = 'statuses',
  TYPE = 'types',
  HOST = 'hostIds',
  CITY = 'cities',
  LOCATION = 'locationIds',
  TAGS = 'tagIds',
  HOSTING_TYPES = 'hostingTypes',
  ATTENDANCE_TYPE = 'attendanceTypes',
  SITE_COORDINATOR = 'siteCoordinator',
}

export enum ECapacityEmptyTexts {
  UNLIMITED_LONG = 'UNLIMITED_LONG',
  UNLIMITED_SHORT = 'UNLIMITED_SHORT',
  LIMITED_WITH_WORKSHOP = 'LIMITED_WITH_WORKSHOP',
}

export type TWorkshopHostDataUI = {
  id: string;
  name: string;
  address: TAddressShortDataUI;
  allowedOperations: TAllowedOperationsUI;
};
export type TWorkshopHostDataDB = {
  id: string;
  name: string;
  address: TAddressShortDataDB;
  allowedOperations: TAllowedOperationsDB;
};

export type TWorkshopLocationUI = {
  id: string;
  name: string;
  address: TAddressShortDataUI;
  nameAndAddress: string;
  allowedOperations: TAllowedOperationsUI;
};
export type TWorkshopLocationDB = {
  id: string;
  name: string;
  address: TAddressShortDataDB;
  allowedOperations: TAllowedOperationsDB;
};

export type TWorkshopSiteCoordinatorUI = {
  allowedOperations: TAllowedOperationsUI;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  phone?: string;
  profilePicture?: string;
  fullName?: string;
};
export type TWorkshopSiteCoordinatorDB = {
  allowedOperations: TAllowedOperationsDB;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  phone?: string;
  profilePicture?: string;
};

export type TPhotoGalleryUI = {
  originalPhoto: string;
  thumbnailPhoto: string;
};

export type TPhotoGalleryDB = {
  originalPhoto: string;
  thumbnailPhoto: string;
};

export type TWorkshopGalleryUI = {
  photos: TPhotoGalleryUI[];
};

export type TWorkshopGalleryDB = {
  photos: TPhotoGalleryDB[];
};

export type TAddWorkshopPhotoUI = {
  workshopId: string;
  photoUrl: string;
};

export type TAddWorkshopPhotoDB = {
  workshopId: string;
  photoUrl: string;
};

export type TWorkshopRegistrantsUrl = {
  workshopId: string;
  localDate: string;
};

export type TSessionsUI = {
  facilitator: TWorkshopSiteCoordinatorUI;
  id: string;
  partType: EWorkshopPartType;
};

export type TSessionsDB = {
  facilitator: TWorkshopSiteCoordinatorDB;
  id: string;
  partType: EWorkshopPartType;
};

export type TCapacityUI = {
  allowedOperations: TAllowedOperationsUI;
  value: string;
  showValue?: string;
};
export type TCapacityDB = {
  allowedOperations: TAllowedOperationsDB;
  value: string;
};

export type TClassItemUI = {
  ageGroup: TAgeGroupAllowedOperationsValueUI;
  capacity: TCapacityUI;
  id: string;
  language: TLanguageAllowedOperationsValueUI;
  sessions: TSessionsUI[];
  registrantsCount: number;
};

export type TClassItemDB = {
  ageGroup: TAgeGroupAllowedOperationsValueDB;
  capacity: TCapacityDB;
  id: string;
  language: TLanguageAllowedOperationsValueDB;
  sessions: TSessionsDB[];
  registrantsCount: number;
};

export type TClassesDataUI = {
  allowedOperations: TAllowedOperationsUI;
  value?: TClassItemUI[];
};

export type TClassesDataDB = {
  allowedOperations: TAllowedOperationsDB;
  value?: TClassItemDB[];
};

export type TPossibleAgeGroupUI = {
  value: EAgeGroup;
  label: EAgeGroup;
};

export type TFlyersDataUI = {
  existingAgeGroups: EAgeGroup[];
  parts: TPartsUI[];
};

export enum EWorkshopDetailsAllowedActions {
  // actions came from BE
  DELETE = 'DELETE',
  CLOSE = 'CLOSE',
  PUBLISH = 'PUBLISH',
  DUPLICATE = 'DUPLICATE',
  CANCEL = 'CANCEL',
  RESCHEDULE = 'RESCHEDULE',
  REPUBLISH = 'REPUBLISH',
  // actions added on FE
  DOWNLOAD_FLYER = 'DOWNLOAD_FLYER',
  DOWNLOAD_LANDING_QR = 'DOWNLOAD_LANDING_QR',
  COPY_URL = 'COPY_URL',
}

export type TPartsUI = {
  dateTime?: TAllowedOperationsValueUI<string> | null;
  isPassed: boolean;
  partType: EWorkshopPartType;
  workshopPartId: string;
};

export type TPartsDB = {
  dateTime?: TAllowedOperationsValueDB<string> | null;
  isPassed: boolean;
  partType: EWorkshopPartType;
  workshopPartId: string;
};

export type TWorkshopDetailsDataUI = {
  actualSessionDates: string[];
  allowedActions: EWorkshopDetailsAllowedActions[];
  attendanceType: EAttendanceType;
  capacity: TCapacityUI;
  childcareProvided: TAllowedOperationsValueUI<boolean> | null;
  classes: TClassesDataUI;
  customQuestion: TAllowedOperationsValueUI<string>;
  description?: TAllowedOperationsValueUI<string>;
  foodProvided: TAllowedOperationsValueUI<boolean>;
  host: TWorkshopHostDataUI;
  hostedPrivately: boolean;
  id: string;
  location: TWorkshopLocationUI;
  locationSameAsHost: boolean;
  meetingLink: TAllowedOperationsValueUI<string> | null;
  photo: TAllowedOperationsValueUI<string>;
  possibleAgeGroups: EAgeGroup[];
  shipmentAddress: TShortAddressWithAllowOperationsUI;
  shipmentAddressSameAsLocationAddress: boolean;
  siteCoordinators: TWorkshopSiteCoordinatorUI[];
  status: EWorkshopStatus;
  type: EWorkshopStandaloneType | EWorkshopSeriesType;
  typeCategory: EWorkshopCategory;
  hasStartedPart: boolean;
  parts?: TPartsUI[];
  flyerData: TFlyersDataUI;
  isCCP: boolean;
};

export type TWorkshopDetailsDataDB = {
  actualSessionDates: string[];
  allowedActions: EWorkshopDetailsAllowedActions[];
  attendanceType: EAttendanceType;
  capacity: TCapacityDB;
  childcareProvided: TAllowedOperationsValueDB<boolean>;
  classes: TClassesDataDB;
  customQuestion: TAllowedOperationsValueDB<string>;
  description?: TAllowedOperationsValueDB<string>;
  foodProvided: TAllowedOperationsValueDB<boolean>;
  host: TWorkshopHostDataDB;
  hostedPrivately: boolean;
  id: string;
  location: TWorkshopLocationDB;
  locationSameAsHost: boolean;
  meetingLink: TAllowedOperationsValueDB<string> | null;
  photo: TAllowedOperationsValueDB<string>;
  possibleAgeGroups: EAgeGroup[];
  shipmentAddress: TShortAddressWithAllowOperationsDB;
  shipmentAddressSameAsLocationAddress: boolean;
  siteCoordinators: { value: TWorkshopSiteCoordinatorDB[] };
  status: EWorkshopStatus;
  type: EWorkshopStandaloneType | EWorkshopSeriesType;
  typeCategory: EWorkshopCategory;
  hasStartedPart: boolean;
  parts: TPartsDB[];
};

export type TWorkshopChildCareUI = {
  ageGroup: EWorkshopChildcareAgeGroups;
  count: string;
};

export type TWorkshopChildCareDB = {
  ageGroup: EWorkshopChildcareAgeGroups;
  count: string;
};

export type TWorkshopSessionDataUI = {
  partType: EWorkshopPartType;
  date: string;
  facilitator: string;
};

export type TWorkshopSessionDataDB = {
  partType: EWorkshopPartType;
  dateTime?: string;
  facilitatorId?: string;
};

export type TWorkshopClassDataUI = {
  workshopId: string;
  age: EAgeGroup;
  language: EWorkshopLanguage;
  capacity?: string;
  sessions: TWorkshopSessionDataUI[];
};

export type TWorkshopClassDataDB = {
  workshopId: string;
  ageGroup: EAgeGroup;
  language: EWorkshopLanguage;
  capacity?: string;
  sessions: TWorkshopSessionDataDB[];
};

export type TDuplicateWorkshopUI = {
  workshopId: string;
};

export type TDuplicateWorkshopDB = {
  workshopId: string;
};

export type TDeleteWorkshopClassUI = {
  classId: string;
  workshopId: string;
};

export type TDeleteWorkshopClassDB = {
  classId: string;
  workshopId: string;
  description?: TAllowedOperationsValueDB<string>;
};

export type TWorkshopCreateUI = {
  type: EWorkshopSeriesType | EWorkshopStandaloneType;
  host: string;
  hostedPrivately: boolean;
  attendanceType: EAttendanceType;
};

export type TWorkshopCreateDB = {
  type: EWorkshopSeriesType | EWorkshopStandaloneType;
  hostId: string;
  hostedPrivately: boolean;
  attendanceType: EAttendanceType;
};

export type TWorkshopSessionItemUI = {
  ageGroup: EAgeGroup;
  language: EWorkshopLanguage;
  count: number;
};
export type TWorkshopSessionItemDB = {
  ageGroup: EAgeGroup;
  language: EWorkshopLanguage;
  count: number;
};

export type TWorkshopSessionDateUI = {
  partType: EWorkshopPartType;
  date: string | null;
};
export type TWorkshopSessionDateDB = Record<EWorkshopPartType, string | null>;

export type TWorkshopDataUI = {
  workshopId: string;
  hostName: string;
  hostId: string;
  hostedPrivately: boolean;
  attendanceType: EAttendanceType;
  createdDate: string;
  city: string;
  workshopType: EWorkshopStandaloneType | EWorkshopSeriesType;
  workshopStatus: EWorkshopStatus;
  workshopCategory: EWorkshopCategory;
  englishSessions?: TWorkshopSessionItemUI[];
  spanishSessions?: TWorkshopSessionItemUI[];
  tags?: TTagUI[];
  locationId: string;
  locationName: string;
  capacity: string | null;
  registrantCount: number;
  sessionDates: TWorkshopSessionDateUI[];
  siteCoordinators?: string[];
};
export type TWorkshopDataDB = {
  workshopId: string;
  hostName: string;
  hostId: string;
  hostedPrivately: boolean;
  attendanceType: EAttendanceType;
  createdDate: string;
  city: string;
  workshopType: EWorkshopStandaloneType | EWorkshopSeriesType;
  workshopStatus: EWorkshopStatus;
  workshopCategory: EWorkshopCategory;
  englishSessions?: TWorkshopSessionItemDB[];
  spanishSessions?: TWorkshopSessionItemDB[];
  tags?: TTagDB[];
  locationId: string;
  locationName: string;
  capacity: string | null;
  registrantCount: number;
  sessionDates: TWorkshopSessionDateDB;
  siteCoordinators?: string[];
};

export type TAgeGroupAllowedOperationsValueUI = {
  allowedOperations: TAllowedOperationsUI;
  value: TPossibleAgeGroupUI;
};

export type TAgeGroupAllowedOperationsValueDB = {
  allowedOperations: TAllowedOperationsDB;
  value: EAgeGroup;
};

export type TLanguageAllowedOperationsValueUI = {
  allowedOperations: TAllowedOperationsUI;
  value: TLanguageOptionUI;
};

export type TLanguageAllowedOperationsValueDB = {
  allowedOperations: TAllowedOperationsDB;
  value: EWorkshopLanguage;
};

export type TWorkshopClassAgeGroupEditUI = {
  id: string;
  workshopId: string;
  value: string;
};
export type TWorkshopClassAgeGroupEditDB = {
  workshopClassId: string;
  workshopId: string;
  ageGroup: string;
};

export type TWorkshopClassLanguageEditUI = {
  id: string;
  workshopId: string;
  value: EWorkshopLanguage;
};
export type TWorkshopClassLanguageEditDB = {
  workshopClassId: string;
  workshopId: string;
  language: EWorkshopLanguage;
};

export type TWorkshopClassDateTimeEditUI = {
  id: string;
  workshopId: string;
  workshopSessionId: string;
  value: string;
};
export type TWorkshopClassDateTimeEditDB = {
  workshopClassId: string;
  workshopId: string;
  workshopSessionId: string;
  dateTime: string;
};
export type TWorkshopPartDateTimeEditDB = {
  workshopId: string;
  workshopPartId: string;
  dateTime: string;
};
export type TWorkshopPartDateTimeEditUI = {
  workshopId: string;
  workshopPartId: string;
  dateTime: string;
};
export type TWorkshopClassFacilitatorEditUI = {
  id: string;
  workshopId: string;
  workshopSessionId: string;
  value: string | null;
};

export type TWorkshopClassFacilitatorEditDB = {
  workshopClassId: string;
  workshopId: string;
  workshopSessionId: string;
  userId: string | null;
};

export type TClassCapacityEditUI = {
  id: string;
  workshopId: string;
  value: string;
};
export type TClassCapacityEditDB = {
  workshopClassId: string;
  workshopId: string;
  capacity: string;
};

export type TWorkshopCapacityEditUI = {
  workshopId: string;
  value: string;
};
export type TWorkshopCapacityEditDB = {
  workshopId: string;
  capacity: string | null;
};

export type TWorkshopLocationEditUI = {
  id: string;
  value: string;
};
export type TWorkshopLocationEditDB = {
  workshopId: string;
  locationId: string;
};
export type TWorkshopHostEditUI = {
  id: string;
  value: string | null;
};
export type TWorkshopHostEditDB = {
  workshopId: string;
  hostId: string | null;
};

export type TWorkshopSiteCoordinatorEditUI = {
  id: string;
  value: string[];
};

export type TWorkshopSiteCoordinatorEditDB = {
  workshopId: string;
  userIds: string[];
};

export type TWorkshopFoodProvidedEditUI = {
  id: string;
  value: boolean;
};
export type TWorkshopFoodProvidedEditDB = {
  workshopId: string;
  foodProvided: boolean;
};

export type TWorkshopMeetingLinkEditUI = {
  id: string;
  value: string | null;
};
export type TWorkshopMeetingLinkEditDB = {
  workshopId: string;
  meetingLink: string | null;
};

export type TWorkshopChildcareProvidedEditUI = {
  id: string;
  value: boolean;
};
export type TWorkshopChildcareProvidedEditDB = {
  workshopId: string;
  childcareProvided: boolean;
};

export type TWorkshopShippingAddressEditUI = TAddressTypesUI & {
  id: string;
};
export type TWorkshopShippingAddressEditDB = {
  workshopId: string;
  address: TAddressTypesDB;
};

export type TWorkshopDescriptionEditUI = {
  id: string;
  value: string;
};
export type TWorkshopDescriptionEditDB = {
  workshopId: string;
  description: string;
};

export type TUpdateCustomQuestionUi = { id: string; question: string };
