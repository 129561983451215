import { ENotificationType, TMenuProps } from '@idaho-aeyc/ui-kit';
import ActionsButtonDetailsPage from 'components/Shared/ActionsButtonDetailsPage';
import { getFormattedDateMDY } from 'utils/dateUtils';
import { downloadCSV } from 'helpers/downloadCsv';
import ActionGroupItemLabel from 'components/Shared/ActionsButtonDetailsPage/ActionGroupItemLabel';
import {
  TRtkErrorType,
  useShowToastNotification,
  useTranslation,
} from '@packages/utils';
import { useLazyExportNotifyMeCSVQuery } from 'redux/apiSlice/subscibers.slice';
import {
  ENotifyMeActions,
  TNotifyMeActionsProps,
} from './NotifyMeActions.types';

const NotifyMeActions: React.FC<TNotifyMeActionsProps> = ({
  selectedRows,
  setShowAlert,
}) => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.subscribers.list.actions',
  });

  const { showToastNotification } = useShowToastNotification();

  const [exportParents] = useLazyExportNotifyMeCSVQuery();

  const ageEnum: { [key: string]: string } = {
    ZERO_TO_ONE: '0-1',
    ONE_TO_TWO: '1-2',
    TWO_TO_THREE: '2-3',
    TWO_TO_FIVE: '2-5',
    THREE_TO_FOUR: '3-4',
    THREE_TO_FIVE: '3-5',
    FOUR_TO_FIVE: '4-5',
  };

  const handleExportAll = async () => {
    try {
      await exportParents().unwrap();
    } catch (err) {
      showToastNotification({
        message: (err as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  if (!selectedRows) return null;

  const exportSelectedRows = () => {
    const { selectedRowsRecords } = selectedRows;

    if (!selectedRowsRecords.length) {
      setShowAlert(true);
      return;
    }

    const selectedRowsTransformed = selectedRowsRecords.map(row => {
      if (row === undefined) return undefined;

      const {
        createdDate,
        firstName,
        lastName,
        phone,
        email,
        cities,
        englishAgeGroups,
        spanishAgeGroups,
        tags,
      } = row;

      const ageGroup = [
        ...englishAgeGroups.map(eg => `${ageEnum[eg.ageGroup]}`),
        ...spanishAgeGroups.map(sg => `${ageEnum[sg.ageGroup]}`),
      ].join(', ');

      return {
        createdDate,
        firstName,
        lastName,
        phone: phone || '',
        email,
        ageGroup,
        cities: cities.join(', '),
        tags: tags?.map(tag => tag.text).join(', '),
      };
    });

    const formattedDate = getFormattedDateMDY();

    downloadCSV(
      [
        'Registration Date',
        'First Name',
        'Last Name',
        'Mobile',
        'Email',
        'Age Group',
        'Cities',
        'Tags'
      ],
      selectedRowsTransformed,
      record => [
        `"${record.createdDate}"`,
        `"${record.firstName}"`,
        `"${record.lastName}"`,
        `"${record.phone}"`,
        `"${record.email}"`,
        `"'${record.ageGroup}"`,
        `"${record.cities}"`,
        `"${record.tags}"`,
      ],
      `Subscribers_NotifyMe_${formattedDate}.csv`,
    );
  };

  const items: TMenuProps['items'] = [
    {
      label: (
        <ActionGroupItemLabel
          onClick={exportSelectedRows}
          // label="Export Selected"
          label={t('export.EXPORT_BTN')}
        />
      ),
      key: ENotifyMeActions.EXPORT,
    },
    {
      label: (
        <ActionGroupItemLabel
          onClick={handleExportAll}
          // label="Export All"
          label={t('export.EXPORT_ALL_BTN')}
        />
      ),
      key: ENotifyMeActions.EXPORT_ALL,
    },
  ];

  return <ActionsButtonDetailsPage items={items} />;
};

export default NotifyMeActions;
