import { TSelectedRows } from 'components/Base/Table/Table.types';
import { TWaitListUI } from 'data/types/registrants.types';

export type TWaitlistActionsProps = {
  selectedRows: TSelectedRows<TWaitListUI>;
  setShowAlert: React.Dispatch<React.SetStateAction<boolean>>;
};
export enum EWaitlistActionsActions {
  EXPORT = 'EXPORT',
  EXPORT_ALL = 'EXPORT ALL',
}
