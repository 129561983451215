import { TSelectedRows } from 'components/Base/Table/Table.types';
import { TWorkshopSubscriberDataUI } from 'data/types/subscribers.types.';

export type TNotifyMeActionsProps = {
  selectedRows: TSelectedRows<TWorkshopSubscriberDataUI>;
  setShowAlert: React.Dispatch<React.SetStateAction<boolean>>;
};
export enum ENotifyMeActions {
  EXPORT = 'EXPORT',
  EXPORT_ALL = 'EXPORT ALL',
}
