import { useEffect, useState } from 'react';
import { useTranslation } from '@packages/utils';
import PageWrapper from 'components/Base/PageWrapper';
import { ETableStoragePaths } from 'components/Base/Table/Table.types';
import useTable from 'components/Base/Table/hooks/useTable';
import { EIconNames } from 'components/Base/Icon';
import { Table } from 'components/Base/Table';
import CardContent from 'components/Base/Layout/Content/CardContent';
import { EWaitListColumnKey, TWaitListUI } from 'data/types/registrants.types';
import {
  useGetWaitListCitiesQuery,
  useGetWaitListLocationsQuery,
  useGetWaitListQuery,
} from 'redux/apiSlice/registrants.slice';
import { TRtkErrorType } from 'data/types/generic.types';
import { useGetTagsListQuery } from 'redux/apiSlice/tags.slice';
import useNavigateWithBackState from 'hooks/useNavigateWithBackState';
import { EKitManagementParentColumnKey } from 'data/types/kitManagement.types';
import { ETagType } from 'data/types/tags.types';
import { Alert } from '@idaho-aeyc/ui-kit';
import useGetColumnsData from './WaitList.data';
import WaitListActions from './Actions';

const WaitList = () => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.waitlist.list',
  });

  const [showAlert, setShowAlert] = useState(false);

  const { defaultColumns, defaultSortParam } = useGetColumnsData();
  const {
    page,
    pageSize,
    sortColumn,
    setPageSize,
    handleTableChange,
    selectedFilters,
    hasSelectedFilter,
    hasFilters,
    columns,
    setColumns,
    onFiltersReset,
    selectedRows,
    setSelectedRows,
  } = useTable<TWaitListUI>({
    storagePath: ETableStoragePaths.SUBSCRIBERS_WAIT_LIST,
    defaultColumns,
    defaultSortColumn: defaultSortParam,
  });

  const { data, isFetching, isLoading, error } = useGetWaitListQuery({
    page,
    pageSize,
    sortColumn,
    filters: selectedFilters,
  });

  const { data: citiesFilters, isSuccess: citiesFiltersSuccess } =
    useGetWaitListCitiesQuery();
  const { data: locationFilters, isSuccess: locationFiltersSuccess } =
    useGetWaitListLocationsQuery();

  const { data: tagsFilters, isSuccess: tagsFiltersSuccess } =
    useGetTagsListQuery(ETagType.REGISTRANT);

  useEffect(() => {
    if (citiesFiltersSuccess) {
      setColumns(p => {
        return p.map(item => {
          if (item.key === EWaitListColumnKey.CITIES) {
            item.filters = citiesFilters;
            item.filterSearch = true;
          }
          return item;
        });
      });
    }
  }, [citiesFiltersSuccess, citiesFilters]);

  useEffect(() => {
    if (tagsFiltersSuccess) {
      setColumns(p => {
        return p.map(item => {
          if (item.key === EKitManagementParentColumnKey.TAGS) {
            item.filters = tagsFilters;
            item.filterSearch = true;
          }
          return item;
        });
      });
    }
  }, [tagsFiltersSuccess, tagsFilters]);

  useEffect(() => {
    if (locationFiltersSuccess) {
      setColumns(p => {
        return p.map(item => {
          if (item.key === EWaitListColumnKey.LOCATION) {
            item.filters = locationFilters;
            item.filterSearch = true;
          }
          return item;
        });
      });
    }
  }, [locationFiltersSuccess, locationFilters]);

  const { goWorkshopDetails } = useNavigateWithBackState();

  const navigateToWorkshopDetails = (record: TWaitListUI) => {
    return {
      onClick: () => goWorkshopDetails(record.workshopId, t('TITLE')),
    };
  };

  const isEmpty = !data?.content.length && !hasSelectedFilter && !isFetching;

  return (
    <PageWrapper
      title={t('TITLE')}
      isLoading={isLoading}
      error={error as TRtkErrorType}
      emptyWrapper={{
        isEmpty,
        alt: 'Sand Timer',
        icon: EIconNames.SAND_TIMER_FILLED,
        emptyText: t('empty-view.EMPTY_TEXT'),
      }}
      actions={
        <WaitListActions
          selectedRows={selectedRows}
          setShowAlert={setShowAlert}
        />
      }
    >
      <CardContent>
        {showAlert && (
          <div className="mx-4" style={{ minHeight: '1px' }}>
            <Alert
              type="warning"
              closable
              message={t('table.ACTION_ALERT_MESSAGE')}
              className="mt-4"
              onClose={() => setShowAlert(false)}
            />
          </div>
        )}
        <Table<TWaitListUI>
          onFiltersReset={onFiltersReset}
          rowKey={record => record.registrantId}
          hasFilters={hasFilters}
          currentPage={page}
          columns={columns}
          dataSource={data?.content}
          loading={isFetching}
          pageSize={pageSize}
          setPageSize={setPageSize}
          totalDataCount={data?.totalElements}
          onChange={handleTableChange}
          storagePath={ETableStoragePaths.SUBSCRIBERS_WAIT_LIST}
          onRow={navigateToWorkshopDetails}
          rowClassName="pointer"
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      </CardContent>
    </PageWrapper>
  );
};

export default WaitList;
