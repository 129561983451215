import { styled } from 'styled-components';
import { Flex } from 'antd';

export const SFlex = styled(Flex)`
  @media (max-width: ${({ theme }) => theme.screenXlMax}px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
`;
