import React from 'react';
import { SCheckableTag, STag } from './Tag.styles';
import { CheckableTagProps, TagProps } from './Tag.types';

export const Tag = ({ ...props }: TagProps) => {
  return <STag {...props} />;
};

export const CheckableTag = ({ ...props }: CheckableTagProps) => {
  return <SCheckableTag {...props} />;
};
