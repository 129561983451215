import { RefObject, useLayoutEffect, useState } from 'react';

type TUseElementResizeProps = {
  ref: RefObject<HTMLDivElement> | null;
};
type TUseElementResizeReturn = {
  width: number;
  height: number;
};

const useElementResize = ({
  ref,
}: TUseElementResizeProps): TUseElementResizeReturn => {
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);

  const handleElementResized = () => {
    if (ref?.current?.offsetHeight !== height) {
      setHeight(ref?.current?.offsetHeight || 0);
    }
    if (ref?.current?.offsetHeight !== width) {
      setWidth(ref?.current?.offsetWidth || 0);
    }
  };

  const resizeObserver = new ResizeObserver(handleElementResized);

  useLayoutEffect(() => {
    if (ref?.current) {
      resizeObserver.observe(ref?.current);
    }
    return function cleanup() {
      resizeObserver.disconnect();
    };
  }, []);

  return {
    width,
    height,
  };
};

export default useElementResize;
