export enum ETagsUrls {
  BASE_URL = 'tags',
  WORKSHOP = 'workshop',
  REGISTRANT = 'registrant',
  ORGANIZATION = 'organization',
  PEOPLE = 'people',
  PARENT_ORDER = 'parent-order',
  SUBSCRIBER = 'subscriber',
}

export const getTagsListUrl = ETagsUrls.BASE_URL;
