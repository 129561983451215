import React, { ReactNode } from 'react';
import { ErrorBoundary as SentryErrorBoundaery } from '@sentry/react';
import SomethingWentWrong from 'components/Shared/ErrorPageView/SomethingWentWrong';

type TErrorBoundaryProps = {
  children: ReactNode;
};

const ErrorBoundary = ({ children }: TErrorBoundaryProps) => {
  return (
    <SentryErrorBoundaery fallback={<SomethingWentWrong />}>
      {children}
    </SentryErrorBoundaery>
  );
};

export default ErrorBoundary;
