import { useTranslation } from '@packages/utils';
import { TColumnType } from 'components/Base/Table/Table.types';
import { Text } from 'components/Base/Typography';
import {
  EAttendanceType,
  EWorkshopFilterParams,
  EWorkshopSeriesType,
  EWorkshopSortParams,
  EWorkshopStandaloneType,
  EWorkshopStatus,
  TWorkshopDataUI,
} from 'data/types/workshops.types';
import WorkshopStatus from 'components/Shared/Statuses/WorkshopStatus';
import AgeGroupesColumn from 'components/Shared/AgeGroupesColumn';
import Tags from 'components/Shared/Tags';
import { useCheckPermission } from 'hooks/useCheckPermission';
import { ETagsUrls } from 'data/urls/tags.url';
import { ETagType } from 'data/types/tags.types';
import { workshopsApi } from 'redux/apiSlice/workshops.slice';
import useGetTableColumnData from 'components/Base/Table/components/SearchFilter';
import { EWorkshopHostedState } from './WorkshopList.types';
import CapacityColumn from './components/CapacityColumn';
import SessionDates from './components/SessionDates';

const useGetColumnsData = () => {
  const { getTableColumnSearchProps } = useGetTableColumnData();

  const { t } = useTranslation();

  const isTagColumnEditable = useCheckPermission('WORKSHOP_TAG_EDIT');

  const defaultColumns: TColumnType<TWorkshopDataUI>[] = [
    {
      title: t(
        'descriptions.admin-portal.workshops.list.table.title.CREATE_DATE',
      ),
      dataIndex: 'createdDate',
      key: EWorkshopSortParams.CREATION_DATE,
      sorter: true,
      width: 130,
    },
    {
      title: t('descriptions.admin-portal.workshops.list.table.title.HOST'),
      dataIndex: 'hostName',
      key: EWorkshopFilterParams.HOST,
      filters: [],
      filterSearch: true,
    },
    {
      title: t('descriptions.admin-portal.workshops.list.table.title.LOCATION'),
      dataIndex: 'locationName',
      key: EWorkshopFilterParams.LOCATION,
      filters: [],
      filterSearch: true,
    },
    {
      title: t('descriptions.admin-portal.workshops.list.table.title.CITY'),
      dataIndex: 'city',
      key: EWorkshopFilterParams.CITY,
      filters: [],
      filterSearch: true,
    },
    {
      title: t('descriptions.admin-portal.workshops.list.table.title.TYPE'),
      dataIndex: 'workshopType',
      key: EWorkshopFilterParams.TYPE,
      filters: [
        ...Object.values(EWorkshopStandaloneType).map(status => ({
          text: t(`enums.workshop-standalone-type.${status}`),
          value: status,
        })),
        ...Object.values(EWorkshopSeriesType).map(status => ({
          text: t(`enums.workshop-series-type.${status}`),
          value: status,
        })),
      ],
      render: (text, record) => (
        <>
          <Text fontSize={12} fontWeight={700}>
            {t(`enums.workshop-category.${record.workshopCategory}`)}
          </Text>
          <br />
          <Text>{t(`enums.workshop-type.${text}`)}</Text>
        </>
      ),
    },
    {
      title: t(
        'descriptions.admin-portal.workshops.list.table.title.SITE_COORDINATOR',
      ),
      dataIndex: 'siteCoordinators',
      key: EWorkshopFilterParams.SITE_COORDINATOR,
      ...getTableColumnSearchProps<TWorkshopDataUI>(),
      render: (_, record) => record?.siteCoordinators?.join(', '),
    },
    {
      title: t('descriptions.admin-portal.workshops.list.table.title.CAPACITY'),
      dataIndex: 'capacity',
      key: 'capacity',
      align: 'right',
      render: (text, record) => (
        <CapacityColumn
          capacity={text}
          registrantCount={record.registrantCount}
        />
      ),
    },
    {
      title: t(
        'descriptions.admin-portal.workshops.list.table.title.AGE_GROUP',
      ),
      dataIndex: 'sessions',
      key: 'sessions',
      width: 100,
      render: (text, record) => (
        <AgeGroupesColumn
          englishSessions={record.englishSessions}
          spanishSessions={record.spanishSessions}
        />
      ),
    },
    {
      title: t(
        'descriptions.admin-portal.workshops.list.table.title.SESSION_DATE',
      ),
      dataIndex: 'sessionDates',
      key: 'sessionDates',
      ellipsis: true,
      render: sesionDates => <SessionDates sessionDates={sesionDates} />,
    },
    {
      title: t('descriptions.admin-portal.workshops.list.table.title.STATUS'),
      dataIndex: 'workshopStatus',
      key: EWorkshopFilterParams.STATUS,
      filters: Object.values(EWorkshopStatus).map(status => ({
        text: t(`enums.workshop-status.${status}`),
        value: status,
      })),
      render: (text: TWorkshopDataUI['workshopStatus']) => (
        <WorkshopStatus text={text} />
      ),
    },
    {
      title: t(
        'descriptions.admin-portal.workshops.list.table.title.ATTENDANCE',
      ),
      dataIndex: 'attendanceTypes',
      key: EWorkshopFilterParams.ATTENDANCE_TYPE,
      filters: Object.values(EAttendanceType).map(type => ({
        text: t(`enums.attendance-type.${type}`),
        value: type,
      })),
      render: (text, record) =>
        t(`enums.attendance-type.${record.attendanceType}`),
    },
    {
      title: t(
        'descriptions.admin-portal.workshops.list.table.title.HOSTING_TYPE',
      ),
      dataIndex: 'hostingTypes',
      key: EWorkshopFilterParams.HOSTING_TYPES,
      filters: Object.values(EWorkshopHostedState).map(type => ({
        text: t(`enums.hosting-type.${type}`),
        value: type,
      })),
      render: (text, record) =>
        record.hostedPrivately
          ? t(`enums.hosting-type.${EWorkshopHostedState.PRIVATE}`)
          : t(`enums.hosting-type.${EWorkshopHostedState.PUBLIC}`),
    },
    {
      title: t('descriptions.admin-portal.workshops.list.table.title.TAG'),
      dataIndex: 'tags',
      key: EWorkshopFilterParams.TAGS,
      filters: [],
      width: 50,
      fixed: 'right',
      filterSearch: true,
      onCell: () => ({
        className: 'custom-cell',
        onClick: e => {
          e.stopPropagation();
        },
      }),
      render: (text, record) => (
        <Tags
          selectedTags={record.tags || []}
          associationId={record.workshopId}
          url={ETagsUrls.WORKSHOP}
          invalidateTagsConfig={[
            { invalidateTag: 'workshops', sliceName: workshopsApi },
          ]}
          tagType={ETagType.WORKSHOP}
          isEditable={isTagColumnEditable}
        />
      ),
    },
  ];
  return { defaultColumns };
};

export default useGetColumnsData;
