import { Card } from '@packages/utils';
import styled from 'styled-components';

export const SCard = styled(Card)`
  &:hover {
    .card-title,
    h4 {
      color: ${({ theme }) => theme.colorPrimaryDefaultNew};
    }
  }
`;
