import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { useTranslation } from '@packages/utils';
import { EIconNames } from 'components/Base/Icon';
import { useCheckPermission } from 'hooks/useCheckPermission';
import { PrivateRouteType, PublicRouteType, RoutesPaths } from './Routes.types';
import useGetWorkshopSubRoutesData from './SubRoutes/workshops';
import useGetKitsSidebarData from './SubRoutes/kitManagement';
import useGetPeopleSubRoutesData from './SubRoutes/people';
import useGetSubscribersSidebarData from './SubRoutes/subscribers';
import useGetSettingsSidebarData from './SubRoutes/settings';
import useGetFamilySubRoutesData from './SubRoutes/family';
import useGetOrganizationSubRoutesData from './SubRoutes/organization';
import useGetELCsSubRoutesData from './SubRoutes/elcs';
import useGetMyELCsSubRoutesData from './SubRoutes/myElcs';

const Login = lazy(() => import('pages/public/Login'));
const ResendPassword = lazy(() => import('pages/public/ResendPassword'));
const WorkshopCancelRegistration = lazy(
  () => import('pages/public/WorkshopCancelRegistration'),
);
const PageNotFound = lazy(
  () => import('../components/Shared/ErrorPageView/PageNotFound'),
);

const useGetSidebarData = () => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.sidebar',
  });
  const { subscribersSubRoutes } = useGetSubscribersSidebarData();
  const { kitManagementSubRoutes } = useGetKitsSidebarData();
  const { settingsSubRoutes } = useGetSettingsSidebarData();
  const { familySubRoutes } = useGetFamilySubRoutesData();
  const { organizationSubRoutes } = useGetOrganizationSubRoutesData();
  const { workshopSubRoutes } = useGetWorkshopSubRoutesData();
  const { peopleSubRoutes } = useGetPeopleSubRoutesData();
  const { ELCsSubRoutes } = useGetELCsSubRoutesData();
  const { MyELCsSubRoutes } = useGetMyELCsSubRoutesData();

  const publicRoutes: PublicRouteType[] = [
    {
      component: Login,
      path: RoutesPaths.LOGIN,
    },
    {
      component: ResendPassword,
      path: RoutesPaths.RESET_PASSWORD,
    },
    {
      component: WorkshopCancelRegistration,
      path: RoutesPaths.WORKSHOP_CANCEL_REGISTRATION,
    },
    {
      component: PageNotFound,
      path: RoutesPaths.WORKSHOP_CANCEL_REGISTRATION_NOT_FOUND,
    },
  ];

  const privateRoutes: PrivateRouteType[] = [
    {
      title: t('WORKSHOPS'),
      component: Outlet,
      selectedIcon: EIconNames.HANDBAG_FILLED,
      icon: EIconNames.HANDBAG,
      path: RoutesPaths.WORKSHOP,
      show: true,
      subRoutes: workshopSubRoutes,
      showByPermissions: useCheckPermission(['WORKSHOP_VIEW']),
    },
    {
      title: t('KITS_MANAGEMENT'),
      component: Outlet,
      selectedIcon: EIconNames.BOOK_FILLED,
      icon: EIconNames.BOOK,
      path: RoutesPaths.KIT_MANAGEMENT,
      show: true,
      subRoutes: kitManagementSubRoutes,
      showByPermissions: useCheckPermission([
        'PART_ORDER_VIEW',
        'PARENT_ORDER_VIEW',
      ]),
    },
    {
      title: t('ELCS'),
      component: Outlet,
      selectedIcon: EIconNames.CHESS_QUEEN,
      icon: EIconNames.CHESS_QUEEN_OUTLINE,
      path: RoutesPaths.ELCS,
      show: true,
      subRoutes: ELCsSubRoutes,
      showByPermissions: useCheckPermission('ELC_LIST_VIEW'),
    },
    {
      title: t('MYELCS'),
      component: Outlet,
      selectedIcon: EIconNames.CHESS_QUEEN,
      icon: EIconNames.CHESS_QUEEN_OUTLINE,
      path: RoutesPaths.MYELCS,
      show: true,
      subRoutes: MyELCsSubRoutes,
      showByPermissions: useCheckPermission('MY_ELC_LIST_VIEW'),
    },
    {
      title: t('ORGANIZATIONS'),
      component: Outlet,
      selectedIcon: EIconNames.PEOPLE_GROUP_FILLED,
      icon: EIconNames.PEOPLE_GROUP,
      path: RoutesPaths.ORGANIZATION,
      show: true,
      subRoutes: organizationSubRoutes,
      showByPermissions: useCheckPermission('ORGANIZATION_VIEW'),
    },
    {
      title: t('PEOPLE'),
      component: Outlet,
      selectedIcon: EIconNames.PEOPLE_PROFILE_FILLED,
      icon: EIconNames.PEOPLE_PROFILE,
      path: RoutesPaths.PEOPLE,
      show: true,
      subRoutes: peopleSubRoutes,
      showByPermissions: useCheckPermission('PEOPLE_VIEW'),
    },
    {
      title: t('FAMILY'),
      component: Outlet,
      selectedIcon: EIconNames.FAMILY_FILLED,
      icon: EIconNames.FAMILY,
      path: RoutesPaths.FAMILY,
      show: true,
      subRoutes: familySubRoutes,
      showByPermissions: useCheckPermission('FAMILY_VIEW'),
    },
    {
      title: t('SUBSCRIBERS'),
      component: Outlet,
      selectedIcon: EIconNames.DESTINY_FILLED,
      icon: EIconNames.DESTINY,
      path: RoutesPaths.SUBSCRIBERS,
      show: true,
      subRoutes: subscribersSubRoutes,
      showByPermissions: useCheckPermission([
        'SUBSCRIBERS_VIEW',
        'WAIT_LIST_VIEW',
      ]),
    },
    {
      title: t('SETTINGS'),
      component: Outlet,
      selectedIcon: EIconNames.SETTINGS_FILLED,
      icon: EIconNames.SETTINGS,
      path: RoutesPaths.SETTINGS,
      show: true,
      subRoutes: settingsSubRoutes,
      showByPermissions: useCheckPermission([
        'ADMIN_LIST_VIEW',
        'LIGHT_BOX_VIEW',
        'SECURITY_VIEW',
      ]),
    },
    {
      title: t('404'),
      component: PageNotFound,
      path: RoutesPaths.PAGE_NOT_FOUND,
      show: false,
      showByPermissions: true,
    },
  ];

  const privateRoutesFilteredByPermission = privateRoutes
    .filter(item => item.showByPermissions)
    .map(el => {
      if (el.subRoutes) {
        return {
          ...el,
          subRoutes: el.subRoutes.filter(i => i.showByPermissions),
        };
      }
      return el;
    });

  return { publicRoutes, privateRoutes: privateRoutesFilteredByPermission };
};

export default useGetSidebarData;
