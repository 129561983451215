import { useTranslation } from '@packages/utils';
import { useGetElcMeetingAttendeesQuery } from 'redux/apiSlice/elcs.slice';
import { Alert } from 'components/Base/Alert';
import { EIconNames, Icon } from 'components/Base/Icon';
import AEYCLead from './AEYCLead';
import { TAttendeesTabProps } from './AttendeesTab.types';
import PM from './PM';
import FiscalAgent from './FiscalAgent';
import GuestAdd from './Guest/GuestAdd';
import GuestWithActions from './Guest/GuestWithActions';

const AttendeesTab = ({ elcId, meetingId }: TAttendeesTabProps) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.elcs.detail.meetings-tab.details.attendees',
  });
  const { data, isLoading } = useGetElcMeetingAttendeesQuery(
    {
      meetingId,
    },
    {
      skip: !meetingId,
    },
  );

  if (!data) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  const {
    lead,
    firstProjectManager,
    secondProjectManager,
    fiscalAgent,
    guests,
  } = data;

  return (
    <>
      <Alert
        message={t('ATTEND_MESSAGE')}
        type="info"
        showIcon
        icon={<Icon icon={EIconNames.INFO_OUTLINED} />}
      />
      {!isLoading && (
        <>
          <AEYCLead data={lead} elcId={elcId} meetingId={meetingId} />
          <PM data={firstProjectManager} elcId={elcId} meetingId={meetingId} />
          {secondProjectManager && (
            <PM
              data={secondProjectManager}
              elcId={elcId}
              meetingId={meetingId}
            />
          )}
          <FiscalAgent data={fiscalAgent} elcId={elcId} meetingId={meetingId} />
          {guests.map(guest => (
            <GuestWithActions
              key={guest.attendeeId}
              data={guest}
              elcId={elcId}
              meetingId={meetingId}
            />
          ))}
        </>
      )}
      <GuestAdd elcId={elcId} meetingId={meetingId} />
    </>
  );
};

export default AttendeesTab;
