import { Checkbox, Row } from '@idaho-aeyc/ui-kit';
import styled from 'styled-components';

export const SCeckbox = styled(Checkbox)`
  &&& {
    .ant-checkbox-checked {
      border-spacing: initial;
    }
  }
`;

export const SColumnRow = styled(Row)`
  & {
    flex-direction: column;
  }
`;

export const SEditChildcareWrapper = styled(Row)<{ width?: number }>`
  & {
    width: ${({ width }) => (width ? `${width}px` : '100%')};
  }
`;
