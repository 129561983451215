import { FC, JSXElementConstructor, ReactElement } from 'react';

import { Button, Divider } from 'antd';
import { useTranslation } from '@packages/utils';
import { TCreateCoordinatorModalOpen } from '../../CreateCoordinatorModal/CreateCoordinatorModal.types';

type CoordinatorDropdownProps = {
  menu: ReactElement<any, string | JSXElementConstructor<any>>;
  initialName?: string;
  setOpen: React.Dispatch<React.SetStateAction<TCreateCoordinatorModalOpen>>;
  disabled?: boolean;
};

const CoordinatorDropdown: FC<CoordinatorDropdownProps> = ({
  menu,
  initialName,
  setOpen,
  disabled,
}) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.workshops.details.basic-info.coordinator',
  });

  const handleAddCoordinator = () => {
    setOpen({
      initialName,
      visible: true,
    });
  };

  return (
    <>
      {menu}
      <Divider style={{ margin: '8px 0' }} />
      <Button
        type="link"
        onMouseDown={handleAddCoordinator}
        style={{ display: 'block', width: '100%' }}
        disabled={disabled}
      >
        {t('ADD')}
      </Button>
    </>
  );
};

export default CoordinatorDropdown;
