import dayjs from 'dayjs';
import { Image, useTranslation } from '@packages/utils';
import { TColumnType } from 'components/Base/Table/Table.types';
import AgeGroupesColumn from 'components/Shared/AgeGroupesColumn';
import {
  isWithin48Hours,
  checkDateIsBefore,
  checkDateIsAfter,
} from 'helpers/utils';
import {
  useShowToastNotification,
  ENotificationType,
} from 'components/Base/Notification';
import { useUpdateAttendanceMutation } from 'redux/apiSlice/registrants.slice';
import { ESortDirection, TRtkErrorType } from 'data/types/generic.types';
import {
  EAttendanceColumnKey,
  EAttendanceStatus,
  ERegistrantsColumnKey,
  TAttendanceEditUI,
  TAttendanceListUI,
} from 'data/types/registrants.types';
import photographyAllowed from 'assets/images/photographyAllowed.svg';
import photographyNotAllowed from 'assets/images/photographyNotAllowed.svg';
import useGetTableColumnData from 'components/Base/Table/components/SearchFilter';
import { Flex } from 'antd';
import { Switch } from 'components/Base/Switch';
import { EIconNames, Icon } from '@idaho-aeyc/ui-kit';
import styled, { useTheme } from 'styled-components';
import { findIndex } from 'lodash';
import { TPartsUI } from 'data/types/workshops.types';

export const ANSWER_MAX_LENGTH = 44;

const useGetData = ({ workshopId }: { workshopId: string }) => {
  const { t } = useTranslation();
  const { getTableColumnSearchProps } = useGetTableColumnData();
  const defaultSortParam = {
    sortField: EAttendanceColumnKey.LAST_NAME,
    sortDirection: ESortDirection.descend,
  };
  const theme = useTheme();

  const { showToastNotification } = useShowToastNotification();

  const [updateAttendance] = useUpdateAttendanceMutation();

  const checkIsDisabled = (date: string) => {
    return !isWithin48Hours(dayjs(date)) || checkDateIsAfter(dayjs(date));
  };

  const handleSubmit = async ({
    registrantId,
    attendances,
    orientationKitReceived,
  }: {
    registrantId: string;
    attendances?: TAttendanceEditUI[];
    orientationKitReceived: boolean;
  }) => {
    try {
      await updateAttendance({
        attendances: attendances || [],
        registrantId,
        workshopId,
        orientationKitReceived,
      }).unwrap();
      showToastNotification({
        message: t(
          'descriptions.admin-portal.workshops.details.workshop-registrants.actions.edit.modal.SUCCESS_MESSAGE',
        ),
        type: ENotificationType.SUCCESS,
      });
    } catch (err) {
      showToastNotification({
        message: (err as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  const getUpdatedAttendance = ({
    attendances,
    attendanceId,
    attendanceStatus,
    kitCountUpdate,
    sessions,
  }: {
    attendances: TAttendanceEditUI[];
    attendanceId: string;
    attendanceStatus?: EAttendanceStatus;
    kitCountUpdate?: 'ADD' | 'SUBTRACT';
    sessions: TPartsUI[];
  }) => {
    const activeSessionCount = sessions.filter(session =>
      checkDateIsBefore(dayjs(session.dateTime?.value)),
    ).length;

    const currentAttendances: TAttendanceEditUI[] = [];

    for (let counter = 0; counter < activeSessionCount; counter += 1) {
      const item = attendances[counter];
      currentAttendances.push({
        attendanceId: item.sessionAttendanceId,
        attendanceStatus: item.attendanceStatus,
        kitCount: item.kitCount === null ? 0 : item.kitCount,
      });
    }

    const attendanceToUpdate = findIndex(currentAttendances, {
      attendanceId,
    });

    if (currentAttendances.length && attendanceToUpdate !== -1) {
      if (attendanceStatus) {
        currentAttendances[attendanceToUpdate] = {
          ...currentAttendances[attendanceToUpdate],
          attendanceStatus,
        };
      }

      const currentAttendance = currentAttendances[attendanceToUpdate];
      const { kitCount } = currentAttendance;

      if (kitCountUpdate) {
        if (kitCountUpdate === 'ADD') {
          currentAttendances[attendanceToUpdate] = {
            ...currentAttendances[attendanceToUpdate],
            kitCount: kitCount ? kitCount + 1 : 1,
          };
        } else if (kitCountUpdate === 'SUBTRACT') {
          currentAttendances[attendanceToUpdate] = {
            ...currentAttendances[attendanceToUpdate],
            kitCount: kitCount ? kitCount - 1 : 0,
          };
        }
      }
    }

    return currentAttendances;
  };

  const AttendanceButton = styled.button<{
    $side?: string;
    $active: boolean;
    $disabled: boolean;
  }>`
    background: transparent;
    border: 1px solid ${theme.colorBlackMainBorder};
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    font-family: Lato;
    border-radius: 8px;
    height: 100%;
    font-weight: 500;
    transition: all 0.2s;

    ${props =>
      props.$disabled &&
      `
      cursor: not-allowed;
      opacity: 0.5;
      `}

    ${props =>
      props.$side === 'left' &&
      `
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      `}

    ${props =>
      props.$side === 'right' &&
      `
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        `}

    ${props =>
      props.$active &&
      `
      background: ${theme.buttonColorPrimaryNormalBg};
      border-color: ${theme.buttonColorPrimaryNormalBg};
      color: white;
      transition: all 0.2s;
    `}
  `;

  const NumberChangeWrapper = styled.div`
    display: flex;
    border: 1px solid ${theme.colorBlackMainBorder};
    border-radius: 4px;
    padding: 4px;
  `;

  const NumberChangeButton = styled.button<{
    $disabled: boolean;
  }>`
    border: none;
    background: transparent;
    cursor: pointer;

    ${props =>
      props.$disabled &&
      `
      cursor: not-allowed;
      opacity: 0.5;
      `}
  `;

  const defaultColumns: TColumnType<TAttendanceListUI>[] = [
    {
      title: t(
        'descriptions.admin-portal.workshops.details.attendance-list.table.title.FIRST_NAME',
      ),
      dataIndex: 'firstName',
      key: EAttendanceColumnKey.FIRST_NAME,
      sorter: true,
      ...getTableColumnSearchProps<TAttendanceListUI>(),
    },
    {
      title: t(
        'descriptions.admin-portal.workshops.details.attendance-list.table.title.LAST_NAME',
      ),
      dataIndex: 'lastName',
      key: EAttendanceColumnKey.LAST_NAME,
      sorter: true,
      ...getTableColumnSearchProps<TAttendanceListUI>(),
    },
    {
      title: t(
        'descriptions.admin-portal.workshops.details.attendance-list.table.title.PHOTO',
      ),
      dataIndex: 'photo',
      key: EAttendanceColumnKey.PHOTO,
      sorter: true,
      render: (text, record) => (
        <div className="pl-2">
          {record.photo ? (
            <Image src={photographyAllowed} height={24} alt="" />
          ) : (
            <Image src={photographyNotAllowed} height={24} alt="" />
          )}
        </div>
      ),
    },
    {
      title: t(
        'descriptions.admin-portal.workshops.details.attendance-list.table.title.CHILDREN_NAMES',
      ),
      dataIndex: 'childrenNames',
      key: 'childrenNames',
      render: (text, record) => <p>{record?.childrenNames?.join(', ')}</p>,
    },
    {
      title: t(
        'descriptions.admin-portal.workshops.details.attendance-list.table.title.AGE_GROUP',
      ),
      dataIndex: 'ageGroup',
      key: ERegistrantsColumnKey.FILTER_BY_LANGUAGE_AND_AGE_GROUP,
      filters: [],
      filterMode: 'tree',
      filterMultiple: true,
      render: (_, { englishAgeGroups, spanishAgeGroups }) => {
        return (
          <AgeGroupesColumn
            englishSessions={englishAgeGroups}
            spanishSessions={spanishAgeGroups}
          />
        );
      },
    },
    {
      title: t(
        'descriptions.admin-portal.workshops.details.attendance-list.table.title.ATTENDANCE',
      ),
      dataIndex: 'attendance',
      key: 'attendance',
      render: (text, record) => (
        <Flex>
          <AttendanceButton
            $side="left"
            $active={record.attendance === EAttendanceStatus.ATTENDED}
            $disabled={checkIsDisabled(record.sessionDateTime || '')}
            disabled={checkIsDisabled(record.sessionDateTime || '')}
            onClick={() => {
              handleSubmit({
                registrantId: record.registrantId,
                attendances: getUpdatedAttendance({
                  attendances: record.attendances,
                  attendanceId: record.currentAttendanceSessionId || '',
                  attendanceStatus: EAttendanceStatus.ATTENDED,
                  sessions: record.sessions || [],
                }),
                orientationKitReceived: record.binders.received,
              });
            }}
          >
            Attended
          </AttendanceButton>
          <AttendanceButton
            $side="right"
            $disabled={checkIsDisabled(record.sessionDateTime || '')}
            disabled={checkIsDisabled(record.sessionDateTime || '')}
            $active={record.attendance === EAttendanceStatus.ABSENT}
            onClick={() => {
              handleSubmit({
                registrantId: record.registrantId,
                attendances: getUpdatedAttendance({
                  attendances: record.attendances,
                  attendanceId: record.currentAttendanceSessionId || '',
                  attendanceStatus: EAttendanceStatus.ABSENT,
                  sessions: record.sessions || [],
                }),
                orientationKitReceived: record.binders.received,
              });
            }}
          >
            Absent
          </AttendanceButton>
        </Flex>
      ),
    },
    {
      title: t(
        'descriptions.admin-portal.workshops.details.attendance-list.table.title.KITS',
      ),
      dataIndex: 'kits',
      key: 'kits',
      render: (text, record) => (
        <Flex gap={30} align="center">
          <div className="pl-2">{record.countOfChildren}</div>
          <NumberChangeWrapper>
            <NumberChangeButton
              $disabled={
                record.kits <= 0 ||
                checkIsDisabled(record.sessionDateTime || '') ||
                record.attendance === EAttendanceStatus.ABSENT
              }
              disabled={
                record.kits <= 0 ||
                checkIsDisabled(record.sessionDateTime || '') ||
                record.attendance === EAttendanceStatus.ABSENT
              }
              onClick={() => {
                handleSubmit({
                  registrantId: record.registrantId,
                  attendances: getUpdatedAttendance({
                    attendances: record.attendances,
                    attendanceId: record.currentAttendanceSessionId || '',
                    kitCountUpdate: 'SUBTRACT',
                    sessions: record.sessions || [],
                  }),
                  orientationKitReceived: record.binders.received,
                });
              }}
            >
              <Icon
                icon={EIconNames.MINUS}
                size={18}
                color={theme.colorBlackGrey60}
              />
            </NumberChangeButton>
            {record.kits}
            <NumberChangeButton
              $disabled={
                record.kits >= record.countOfChildren ||
                checkIsDisabled(record.sessionDateTime || '') ||
                record.attendance === EAttendanceStatus.ABSENT
              }
              disabled={
                record.kits >= record.countOfChildren ||
                checkIsDisabled(record.sessionDateTime || '') ||
                record.attendance === EAttendanceStatus.ABSENT
              }
              onClick={() => {
                handleSubmit({
                  registrantId: record.registrantId,
                  attendances: getUpdatedAttendance({
                    attendances: record.attendances,
                    attendanceId: record.currentAttendanceSessionId || '',
                    kitCountUpdate: 'ADD',
                    sessions: record.sessions || [],
                  }),
                  orientationKitReceived: record.binders.received,
                });
              }}
            >
              <Icon
                icon={EIconNames.PLUS}
                size={18}
                color={theme.colorBlackGrey60}
              />
            </NumberChangeButton>
          </NumberChangeWrapper>
        </Flex>
      ),
    },
    {
      title: t(
        'descriptions.admin-portal.workshops.details.attendance-list.table.title.BINDERS',
      ),
      dataIndex: 'binders',
      key: 'binders',
      render: (text, record) => (
        <>
          {record.binders.requested ? (
            <Switch
              checkedChildren="Yes"
              unCheckedChildren="No"
              disabled={
                record.attendance === EAttendanceStatus.ABSENT ||
                checkIsDisabled(record.sessionDateTime || '')
              }
              onChange={() => {
                handleSubmit({
                  attendances: getUpdatedAttendance({
                    attendances: record.attendances,
                    attendanceId: record.currentAttendanceSessionId || '',
                    sessions: record.sessions || [],
                  }),
                  registrantId: record.registrantId,
                  orientationKitReceived: !record.binders.received,
                });
              }}
              checked={record.binders.received}
            />
          ) : (
            'Not Required'
          )}
        </>
      ),
    },
    {
      title: ' ',
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      width: 50,
      render: () => null,
    },
  ];

  return { defaultColumns, defaultSortParam };
};

export default useGetData;
