import { FC, useEffect, useState } from 'react';
import { Row, useTranslation } from '@packages/utils';
import { useCheckPermission } from 'hooks/useCheckPermission';
import { EIconNames, Icon } from 'components/Base/Icon';
import { styled, useTheme } from 'styled-components';
import { InplaceEditLabels } from 'components/Base/EditableFields/components/InplaceEditLabel';
import { useGetPeopleIdentityQuery } from 'redux/apiSlice/people.slice';
import { useEditSiteCoordinatorMutation } from 'redux/apiSlice/workshops.slice';
import CreateCoordinatorModal from './CreateCoordinatorModal';
import { TCoordinatorProps } from './Coordinator.types';
import { TCreateCoordinatorModalOpen } from './CreateCoordinatorModal/CreateCoordinatorModal.types';
import CoordinatorSelector from './CoordinatorSelector';
import CoordinatorInfo from './CoordinatorInfo';

const EditableSelect = styled.div`
  padding: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: Lato;

  span {
    color: #808080;
  }

  .icon {
    display: none !important;
  }

  &:hover {
    background: #ececec;
    border-radius: 4px;

    .icon {
      display: inline-block !important;
    }
  }
`;

const CoordinatorTag = styled.div`
  font-family: Lato;
  border-radius: 4px;
  padding: 2.5px 8px;
  border: 1px solid #c8dcde;
  background: #e9f7f7;
  margin-right: 4px;
  line-height: 16px;
  margin-right: 8px;
`;

const CoordinatorPlaceholder = styled.span`
  font-family: Lato;
  color: #808080;
  margin-left: 8px;
`;

const Coordinator: FC<TCoordinatorProps> = ({ coordinators, id }) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.workshops.details.basic-info.coordinator',
  });
  const theme = useTheme();

  const { data: peopleIdentityOptions, isLoading } =
    useGetPeopleIdentityQuery();

  const [selectedCoordinators, setSelectedCoordinators] = useState<any[]>([]);

  const [open, setOpen] = useState<TCreateCoordinatorModalOpen>({
    visible: false,
    initialName: '',
  });
  const [isEditingCoordinator, setIsEditingCoordinator] =
    useState<boolean>(false);

  const [coordinatorId, setCoordinatorId] = useState<string>('');

  const [editSiteCoordinator] = useEditSiteCoordinatorMutation();

  useEffect(() => {
    if (!coordinatorId) {
      return;
    }
    (async () => {
      const params = {
        id,
        value: [
          ...(selectedCoordinators?.map(coordinator => coordinator.value) ||
            []),
          coordinatorId,
        ],
      };

      await editSiteCoordinator(params).unwrap();
    })();
  }, [coordinatorId]);

  return (
    <>
      <Row>
        <InplaceEditLabels label={t('LABEL')} icon={EIconNames.COORDINATOR} />
        {!useCheckPermission(['WORKSHOP_EDIT']) && (
          <div>
            {coordinators?.length ? (
              <>
                {coordinators?.map(coordinator => (
                  <CoordinatorInfo
                    display={!!coordinator?.id}
                    popoverTitle={coordinator?.fullName}
                    email={coordinator?.email}
                    phone={coordinator?.phone}
                  >
                    <CoordinatorTag>{coordinator.fullName}</CoordinatorTag>
                  </CoordinatorInfo>
                ))}
              </>
            ) : (
              <CoordinatorPlaceholder>N/A</CoordinatorPlaceholder>
            )}
          </div>
        )}
        {useCheckPermission(['WORKSHOP_EDIT']) && (
          <>
            {isEditingCoordinator ? (
              <CoordinatorSelector
                id={id}
                coordinators={coordinators}
                options={peopleIdentityOptions}
                selectedCoordinators={selectedCoordinators}
                setSelectedCoordinators={setSelectedCoordinators}
                setOpen={setOpen}
                isLoading={isLoading}
                onBlur={setIsEditingCoordinator}
              />
            ) : (
              <EditableSelect onClick={() => setIsEditingCoordinator(true)}>
                {coordinators?.length ? (
                  coordinators?.map(coordinator => (
                    <CoordinatorInfo
                      display={!!coordinator?.id}
                      popoverTitle={coordinator?.fullName}
                      email={coordinator?.email}
                      phone={coordinator?.phone}
                    >
                      <CoordinatorTag>{coordinator.fullName}</CoordinatorTag>
                    </CoordinatorInfo>
                  ))
                ) : (
                  <span>Select Coordinator</span>
                )}
                <Icon
                  icon={EIconNames.EDIT_OUTLINED}
                  size={20}
                  color={theme.colorBlackIconColor}
                />
              </EditableSelect>
            )}
          </>
        )}
      </Row>
      <CreateCoordinatorModal
        open={open}
        setOpen={setOpen}
        label={t('MODAL_LABEL')}
        setCoordinatorId={setCoordinatorId}
      />
    </>
  );
};

export default Coordinator;
