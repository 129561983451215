export interface IconProps {
  /**
   * Optional, fill color
   */
  fill?: string;
  /**
   * Optional, size of the svg icon
   */
  size?: number;
  /**
   * Optional, on click event.
   */
  onClick?: (e: React.MouseEvent<SVGElement>) => void;
}

export function EmailOutline({
  fill = '#656565',
  size = 20,
  onClick,
}: IconProps) {
  return (
    <svg
      id="icon-email-outline"
      width={size}
      height={size}
      viewBox="0 0 32 32"
      onClick={onClick}
    >
      <path
        fill={fill}
        d="M3.2 28.8c-0.88 0-1.632-0.314-2.261-0.941-0.58-0.561-0.94-1.346-0.94-2.215 0-0.015 0-0.031 0-0.046l-0 0.002v-19.2c0-0.88 0.314-1.632 0.941-2.261 0.561-0.58 1.346-0.939 2.215-0.939 0.015 0 0.031 0 0.046 0l-0.002-0h25.6c0.88 0 1.632 0.314 2.261 0.941 0.624 0.626 0.939 1.379 0.939 2.259v19.2c0 0.88-0.314 1.632-0.939 2.259-0.561 0.581-1.347 0.941-2.217 0.941-0.015 0-0.031-0-0.046-0l0.002 0h-25.6zM16 17.6l-12.8-8v16h25.6v-16l-12.8 8zM16 14.4l12.8-8h-25.6l12.8 8zM3.2 9.6v0z"
      />
    </svg>
  );
}

export function PhoneOutline({
  fill = '#656565',
  size = 20,
  onClick,
}: IconProps) {
  return (
    <svg
      id="icon-phone-outline"
      width={size}
      height={size}
      viewBox="0 0 32 32"
      onClick={onClick}
    >
      <path
        fill={fill}
        d="M30.133 32c-3.703 0-7.364-0.807-10.977-2.423-3.614-1.614-6.903-3.902-9.866-6.866s-5.252-6.252-6.866-9.866c-1.618-3.614-2.423-7.275-2.423-10.977 0-0.533 0.178-0.978 0.533-1.333s0.8-0.533 1.333-0.533h7.2c0.003 0 0.005-0 0.008-0 0.424 0 0.811 0.16 1.104 0.423l-0.002-0.001c0.301 0.247 0.511 0.595 0.576 0.991l0.001 0.009 1.156 6.222c0.059 0.475 0.044 0.875-0.044 1.2s-0.252 0.608-0.489 0.844l-4.311 4.356c0.592 1.097 1.298 2.156 2.11 3.177 0.816 1.022 1.712 2.009 2.69 2.956 0.919 0.919 1.881 1.771 2.889 2.556 1.008 0.784 2.075 1.502 3.2 2.155l4.178-4.178c0.285-0.275 0.636-0.482 1.028-0.596l0.018-0.004c0.261-0.087 0.562-0.137 0.875-0.137 0.138 0 0.273 0.010 0.406 0.029l-0.015-0.002 6.133 1.244c0.414 0.119 0.756 0.334 1.022 0.645 0.248 0.275 0.4 0.642 0.4 1.044v7.2c0 0.533-0.178 0.978-0.533 1.333s-0.8 0.533-1.333 0.533zM5.378 10.667l2.933-2.933-0.756-4.178h-3.956c0.148 1.214 0.356 2.414 0.622 3.6s0.652 2.356 1.156 3.511zM21.289 26.578c1.156 0.503 2.334 0.903 3.534 1.2s2.407 0.489 3.621 0.578v-3.911l-4.178-0.844-2.978 2.978z"
      />
    </svg>
  );
}
