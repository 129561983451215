import { useTranslation } from '@packages/utils';
import { Col, Row } from 'components/Base/Grid';
import { TColumnType } from 'components/Base/Table/Table.types';
import AgeGroupesColumn from 'components/Shared/AgeGroupesColumn';
import { CopyTextButton } from 'components/Base/CopyTextButton';
import { EAgeGroup, ESortDirection } from 'data/types/generic.types';
import { EWaitListColumnKey, TWaitListUI } from 'data/types/registrants.types';
import { ParagraphEllipsis } from 'components/Shared/ParagraphEllipsis';
import useGetTableColumnData from 'components/Base/Table/components/SearchFilter';

export const ANSWER_MAX_LENGTH = 44;

const useGetData = () => {
  const { t } = useTranslation();
  const { getTableColumnSearchProps } = useGetTableColumnData();
  const defaultSortParam = {
    sortField: EWaitListColumnKey.CREATION_DATE,
    sortDirection: ESortDirection.descend,
  };

  const defaultColumns: TColumnType<TWaitListUI>[] = [
    {
      title: t(
        'descriptions.admin-portal.workshops.details.workshop-wait-list.table.title.REGISTRATION_DATE',
      ),
      dataIndex: 'createdDate',
      key: EWaitListColumnKey.CREATION_DATE,
      sorter: true,
    },
    {
      title: t(
        'descriptions.admin-portal.workshops.details.workshop-wait-list.table.title.FIRST_NAME',
      ),
      dataIndex: 'firstName',
      key: EWaitListColumnKey.FIRST_NAME,
      sorter: true,
      ...getTableColumnSearchProps<TWaitListUI>(),
    },
    {
      title: t(
        'descriptions.admin-portal.workshops.details.workshop-wait-list.table.title.LAST_NAME',
      ),
      dataIndex: 'lastName',
      key: EWaitListColumnKey.LAST_NAME,
      sorter: true,
      ...getTableColumnSearchProps<TWaitListUI>(),
    },
    {
      title: t(
        'descriptions.admin-portal.workshops.details.workshop-wait-list.table.title.AGE_GROUP',
      ),
      dataIndex: 'ageGroup',
      key: EWaitListColumnKey.AGE_GROUP,
      filters: Object.values(EAgeGroup).map(ageGroup => ({
        text: t(`enums.age-group.${ageGroup}`),
        value: ageGroup,
      })),
      render: (_, { englishAgeGroups, spanishAgeGroups }) => {
        return (
          <AgeGroupesColumn
            englishSessions={englishAgeGroups}
            spanishSessions={spanishAgeGroups}
          />
        );
      },
    },

    {
      title: t(
        'descriptions.admin-portal.workshops.details.workshop-wait-list.table.title.NUMBER_OF_CHILDREN',
      ),
      dataIndex: 'countOfChildren',
      key: 'countOfChildren',
      onCell: () => ({
        style: { width: '70px' },
      }),
    },
    {
      title: t(
        'descriptions.admin-portal.workshops.details.workshop-wait-list.table.title.CHILDREN_NAMES',
      ),
      dataIndex: 'childrenNames',
      key: 'childrenNames',
      render: (text, record) => (
        <Row gutter={[0, 8]}>
          {record?.childrenNames?.map(name => (
            <Col key={name} span={24}>
              {name}
            </Col>
          ))}
        </Row>
      ),
      onCell: () => ({
        style: { width: '180px' },
      }),
    },
    {
      title: t(
        'descriptions.admin-portal.workshops.details.workshop-wait-list.table.title.MOBILE',
      ),
      dataIndex: 'phone',
      key: 'phone',
      ellipsis: true,
    },
    {
      title: t(
        'descriptions.admin-portal.workshops.details.workshop-wait-list.table.title.EMAIL',
      ),
      dataIndex: 'email',
      key: 'email',
      onCell: () => ({
        className: 'custom-cell',
        style: { maxWidth: '130px' },
        onClick: e => {
          e.stopPropagation();
        },
      }),
      render: (text, record) => {
        return <CopyTextButton title={record.email} />;
      },
    },
    {
      title: t(
        'descriptions.admin-portal.workshops.details.workshop-wait-list.table.title.QUESTION_ANSWER',
      ),
      dataIndex: 'customQuestionAnswer',
      key: 'customQuestionAnswer',
      render: answer => {
        return (
          <ParagraphEllipsis text={answer} textMaxLength={ANSWER_MAX_LENGTH} />
        );
      },
      onCell: () => ({
        style: { minWidth: '180px' },
      }),
    },
    {
      title: ' ',
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      width: 50,
      render: () => null,
    },
  ];

  return { defaultColumns, defaultSortParam };
};

export default useGetData;
