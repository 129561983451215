import React from 'react';
import { ConfigProvider } from 'antd';
import { theme, elcTheme } from '@idaho-aeyc/ui-kit';
import { ThemeProvider } from 'styled-components';
import { GenericStyles } from 'assets/styles/GenericStyles';

type TThemeWrapperProps = {
  children: JSX.Element;
};

type ELCTheme = typeof elcTheme;

declare module 'styled-components' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefaultTheme extends ELCTheme {}
}

const ThemeWrapper = ({ children }: TThemeWrapperProps) => {
  return (
    <ConfigProvider theme={theme(elcTheme)}>
      <ThemeProvider theme={elcTheme}>
        <GenericStyles />
        {children}
      </ThemeProvider>
    </ConfigProvider>
  );
};

export default ThemeWrapper;
