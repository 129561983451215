import {
  useTranslation as I18nUseTranslation,
  Trans,
  UseTranslationOptions,
} from 'react-i18next';
import { EI18nLanguageUI } from '../data/types';

// by default lang is English and will not be translated on i18n language change
// lng as "translate" to make text translatable on i18n language change
// also dynamically can be passed other language from EI18nLanguage enum, wich will change language independantly from i18n language change

export type TTranslationCustomOptions = {
  lng?: EI18nLanguageUI | 'translate';
  namespace?: string;
};

const useTranslation = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: Omit<UseTranslationOptions<any>, 'lng'> & TTranslationCustomOptions,
) => {
  const defaultCustomOptions = {
    lng: EI18nLanguageUI.ENGLISH,
    namespace: 'translation',
  };

  let language: TTranslationCustomOptions['lng'] = EI18nLanguageUI.ENGLISH;

  const { namespace, lng, ...rest } = options || {};

  if (lng === 'translate') {
    language = undefined;
  } else if (lng) {
    language = lng;
  }

  const ns = namespace || defaultCustomOptions.namespace;

  const extendedOptions = {
    ...rest,
    lng: language,
  };

  return I18nUseTranslation(ns, extendedOptions);
};

export { useTranslation, Trans };
