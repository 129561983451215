import { useEffect, useState } from 'react';
import { useTranslation } from '@packages/utils';
import { EIconNames } from 'components/Base/Icon';
import useTable from 'components/Base/Table/hooks/useTable';
import PageWrapper from 'components/Base/PageWrapper';
import {
  EKitManagementWorkshopFilterParams,
  TKitManagementWorkshopUI,
} from 'data/types/kitManagement.types';
import {
  useGetKitManagementWorkshopHostsQuery,
  useGetKitManagementWorkshopLocationsQuery,
  useGetKitManagementWorkshopsQuery,
} from 'redux/apiSlice/kitManagement.slice';
import CardContent from 'components/Base/Layout/Content/CardContent';
import { ETableStoragePaths } from 'components/Base/Table/Table.types';
import { TRtkErrorType } from 'data/types/generic.types';
import useGetColumnsData from './Workshop.data';
import WorkshopActions from './WorkshopActions';
import WorkshopBody from './WorkshopBody';

const KitManagementWorkshop = () => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.kit-management.list',
  });
  const { defaultColumns, defaultFilterParam, defaultSortParam } =
    useGetColumnsData();
  const [showAlert, setShowAlert] = useState(false);
  const {
    page,
    pageSize,
    setPageSize,
    sortColumn,
    selectedFilters,
    hasSelectedFilter,
    setColumns,
    selectedRows,
    onFiltersReset,
    columns,
    handleTableChange,
    setSelectedRows,
  } = useTable<TKitManagementWorkshopUI>({
    defaultSortColumn: defaultSortParam,
    storagePath: ETableStoragePaths.KIT_MANAGEMENT_WORKSHOP,
    defaultColumns,
    defaultFilters: defaultFilterParam,
  });

  const { data, isFetching, isLoading, error } =
    useGetKitManagementWorkshopsQuery({
      page,
      pageSize,
      sortColumn,
      filters: selectedFilters,
    });

  const { data: hostsFilters, isSuccess: hostsFiltersSuccess } =
    useGetKitManagementWorkshopHostsQuery();
  const { data: locationFilters, isSuccess: locationFiltersSuccess } =
    useGetKitManagementWorkshopLocationsQuery();

  useEffect(() => {
    if (hostsFiltersSuccess || locationFiltersSuccess) {
      setColumns(p => {
        return p.map(item => {
          if (
            item.key === EKitManagementWorkshopFilterParams.HOST &&
            hostsFiltersSuccess
          ) {
            item.filters = hostsFilters;
            item.filterSearch = true;
          }
          if (
            item.key === EKitManagementWorkshopFilterParams.LOCATION &&
            locationFiltersSuccess
          ) {
            item.filters = locationFilters;
            item.filterSearch = true;
          }
          return item;
        });
      });
    }
  }, [
    hostsFiltersSuccess,
    hostsFilters,
    locationFiltersSuccess,
    locationFilters,
  ]);

  useEffect(() => {
    if (selectedRows.selectedRowsRecords.length) {
      setShowAlert(false);
    }
  }, [selectedRows]);

  const isEmpty = !data?.content.length && !hasSelectedFilter && !isFetching;

  return (
    <PageWrapper
      title={t('TITLE')}
      isLoading={isLoading}
      error={error as TRtkErrorType}
      actions={
        <WorkshopActions
          setShowAlert={setShowAlert}
          selectedRowKeys={selectedRows.selectedRowsKeys}
          data={data?.content || []}
        />
      }
      emptyWrapper={{
        isEmpty,
        alt: 'Handbag',
        icon: EIconNames.HANDBAG,
        emptyText: t('empty-view.EMPTY_TEXT'),
      }}
    >
      <CardContent>
        <WorkshopBody
          showAlert={showAlert}
          setShowAlert={setShowAlert}
          dataSource={data?.content}
          totalDataCount={data?.totalElements}
          isFetching={isFetching}
          hasFilters={hasSelectedFilter}
          onFiltersReset={onFiltersReset}
          columns={columns}
          pageSize={pageSize}
          setPageSize={setPageSize}
          onChange={handleTableChange}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          currentPage={page}
          storagePath={ETableStoragePaths.KIT_MANAGEMENT_WORKSHOP}
        />
      </CardContent>
    </PageWrapper>
  );
};

export default KitManagementWorkshop;
