import { useEffect, useState } from 'react';
import { useTranslation } from '@packages/utils';
import { useEditSessionFacilitatorMutation } from 'redux/apiSlice/workshops.slice';
import {
  useShowToastNotification,
  ENotificationType,
} from 'components/Base/Notification';
import { CopyTextButton } from 'components/Base/CopyTextButton';
import { TRtkErrorType } from 'data/types/generic.types';
import { EIconNames } from 'components/Base/Icon';
import CreateCoordinatorModal from 'components/Shared/Coordinator/CreateCoordinatorModal';
import CoordinatorInfo from 'components/Shared/Coordinator/CoordinatorInfo';
import EditableSelect from 'components/Base/EditableFields/EditableSelect';
import { InplaceEditLabels } from 'components/Base/EditableFields/components/InplaceEditLabel';
import { TCreateCoordinatorModalOpen } from 'components/Shared/Coordinator/CreateCoordinatorModal/CreateCoordinatorModal.types';
import { useGetPeopleIdentityQuery } from 'redux/apiSlice/people.slice';
import { TFacilitatorSelectProps } from './FacilitatorSelect.types';
import { SFlex } from './FacilitatorSelect.styles';

const FacilitatorSelect = ({
  id,
  facilitator,
  workshopSessionId,
  workshopId,
}: TFacilitatorSelectProps) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.workshops.details.sessions.view.actions.update.facilitator',
  });
  const { showToastNotification } = useShowToastNotification();
  const [open, setOpen] = useState<TCreateCoordinatorModalOpen>({
    initialName: '',
    visible: false,
  });
  const [facilitatorId, setFacilitatorId] = useState<string>('');
  const { data: peopleIdentityOptions } = useGetPeopleIdentityQuery();

  const [editFacilitator, { isError }] = useEditSessionFacilitatorMutation();

  const onEdit = async (value?: string) => {
    try {
      await editFacilitator({
        id,
        workshopSessionId,
        workshopId,
        value: value || facilitatorId,
      }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (e) {
      showToastNotification({
        message: (e as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  useEffect(() => {
    if (!facilitatorId) {
      return;
    }
    (async () => {
      try {
        await onEdit();
        setFacilitatorId('');
      } catch (e) {
        showToastNotification({
          message: (e as TRtkErrorType)?.data?.message,
          type: ENotificationType.ERROR,
        });
      }
    })();
  }, [facilitatorId]);

  return (
    <>
      <SFlex align="center">
        <InplaceEditLabels label={t('LABEL')} icon={EIconNames.COORDINATOR} />
        {!facilitator?.allowedOperations.readonly ? (
          <>
            <EditableSelect
              onEdit={onEdit}
              options={peopleIdentityOptions}
              addNewOption={{
                addButtonLabel: t('ADD_BTN'),
                onClick: currentTypedValue =>
                  setOpen({
                    initialName: currentTypedValue || '',
                    visible: true,
                  }),
                enableAddButton: true,
              }}
              title={{
                label: facilitator?.fullName || '',
                value: facilitator?.id || '',
              }}
              emptyText={t('EMPTY_TEXT')}
              isError={isError}
              readonly={facilitator?.allowedOperations.readonly}
              canRemove={facilitator?.allowedOperations.canRemove}
              tooltip={
                facilitator?.id ? (
                  <CoordinatorInfo
                    phone={facilitator.phone}
                    email={facilitator.email}
                    popoverTitle={t('popover.POPOVER_TITLE')}
                  />
                ) : null
              }
              selectWidth={180}
            />
            <CopyTextButton
              title={facilitator?.fullName}
              className="font-lato"
              textHidden
              textToCopy={facilitator?.email}
            />
          </>
        ) : (
          <CopyTextButton
            title={facilitator?.fullName}
            className="font-lato"
            textToCopy={facilitator?.email}
          />
        )}
      </SFlex>
      <CreateCoordinatorModal
        open={open}
        setOpen={setOpen}
        setCoordinatorId={setFacilitatorId}
        label={t('MODAL_LABEL')}
      />
    </>
  );
};

export default FacilitatorSelect;
