import { PasswordInput, TextArea, Input, PhoneInput } from '@idaho-aeyc/ui-kit';
import styled, { css } from 'styled-components';

export const createInputStyles = () => css``;

export const SInput = styled(Input)``;

export const SPassword = styled(PasswordInput)``;

export const STextArea = styled(TextArea)``;

export const SPhoneInput = styled(PhoneInput)``;
