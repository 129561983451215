import { FC, useState } from 'react';
import TagColumnContent from 'components/Shared/Tags/TagColumnContent/TagColumnContent';
import AddEditTagModal from 'components/Shared/Tags/AddEditTagModal';
import { TagInvalidateConfig } from 'data/types/tags.types';
import { ETagsUrls } from 'data/urls/tags.url';
import { useEditTagsMutation } from 'redux/apiSlice/tags.slice';
import { TTagsProps } from './Tags.types';

const Tags: FC<TTagsProps> = ({
  tagType,
  url,
  selectedTags,
  associationId,
  invalidateTagsConfig,
  isEditable = true,
  isWorkshopSubscribers = false,
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const [editTags, { isLoading }] = useEditTagsMutation();

  const onUpdateTags = async (
    assignedTagIds: string[],
    removedTagIds: string[],
  ) => {
    const payload: {
      associationId: string;
      assignedTagIds: string[];
      removedTagIds: string[];
      url: ETagsUrls;
      invalidateTagsConfig: TagInvalidateConfig[];
      workshopSubscriberId?: string;
    } = {
      associationId,
      assignedTagIds,
      removedTagIds,
      url,
      invalidateTagsConfig,
    };

    if (isWorkshopSubscribers) {
      payload.workshopSubscriberId = associationId;
    }

    await editTags(payload).unwrap();
  };
  return (
    <>
      <TagColumnContent
        isEditable={isEditable}
        onOpenModal={() => setOpen(true)}
        tags={selectedTags}
      />
      {open && (
        <AddEditTagModal
          tagType={tagType}
          open={open}
          setOpen={setOpen}
          confirmLoading={isLoading}
          initialSelectedTags={selectedTags}
          onSubmit={onUpdateTags}
        />
      )}
    </>
  );
};

export default Tags;
