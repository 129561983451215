import { useTranslation } from '@packages/utils';
import {
  EWorkshopSeriesType,
  EWorkshopStandaloneType,
} from 'data/types/workshops.types';
import { TColumnType } from 'components/Base/Table/Table.types';
import useGetTableColumnData from 'components/Base/Table/components/SearchFilter';
import SessionDates from 'pages/private/Workshop/components/WorkshopList/components/SessionDates';
import { CopyTextButton } from 'components/Base/CopyTextButton';
import { EFamilyFilterParams, TFamilyDataUi } from 'data/types/family.types';
import Tags from 'components/Shared/Tags';
import { ETagsUrls } from 'data/urls/tags.url';
import { ETagType } from 'data/types/tags.types';
import { familyApi } from 'redux/apiSlice/family.slice';
import FamilyListActions from '../FamilyListActions';
import { familyListKey } from '../Family.data';

const useGetFamilyColumnsData = () => {
  const { getTableColumnSearchProps } = useGetTableColumnData();

  const { t } = useTranslation({
    keyPrefix: familyListKey.concat('.table.title'),
  });
  const { t: tEnum } = useTranslation({
    keyPrefix: 'enums',
  });

  const familyColumns: TColumnType<TFamilyDataUi>[] = [
    {
      title: t('FIRST_NAME'),
      dataIndex: 'firstName',
      key: EFamilyFilterParams.FIRST_NAME,
      sorter: true,
      ...getTableColumnSearchProps<TFamilyDataUi>(),
    },
    {
      title: t('LAST_NAME'),
      dataIndex: 'lastName',
      key: EFamilyFilterParams.LAST_NAME,
      sorter: true,
      ...getTableColumnSearchProps<TFamilyDataUi>(),
    },
    {
      title: t('CHILDREN_NAMES'),
      dataIndex: 'childrenNames',
      key: 'childrenNames',
      render: (text, record) => record?.childrenNames.join(', '),
      onCell: () => ({
        style: { width: '180px' },
      }),
    },
    {
      title: t('MOBILE'),
      dataIndex: 'phone',
      key: EFamilyFilterParams.MOBILE,
      ellipsis: true,
      ...getTableColumnSearchProps<TFamilyDataUi>('number'),
    },
    {
      title: t('EMAIL'),
      dataIndex: 'email',
      key: EFamilyFilterParams.EMAIL,
      ellipsis: true,
      onCell: () => ({
        className: 'custom-cell',
        onClick: e => {
          e.stopPropagation();
        },
      }),
      render: (text, record) => {
        return <CopyTextButton title={record.email} />;
      },
      ...getTableColumnSearchProps<TFamilyDataUi>(),
    },
    {
      title: t('LOCATION'),
      dataIndex: 'location',
      key: EFamilyFilterParams.LOCATION,
      ellipsis: true,
      filters: [],
      filterSearch: true,
    },
    {
      title: t('TYPE'),
      dataIndex: 'workshopType',
      key: EFamilyFilterParams.TYPE,
      ellipsis: true,
      filters: [
        ...Object.values(EWorkshopStandaloneType).map(status => ({
          text: tEnum(`workshop-standalone-type.${status}`),
          value: status,
        })),
        ...Object.values(EWorkshopSeriesType).map(status => ({
          text: tEnum(`workshop-series-type.${status}`),
          value: status,
        })),
      ],
      render: (text, record) => (
        <>{tEnum(`workshop-type.${record.workshopType}`)}</>
      ),
    },
    {
      title: t('AGE_GROUP'),
      dataIndex: 'ageGroup',
      key: 'ageGroup',
      render: (text, record) => <>{tEnum(`age-group.${record.ageGroup}`)}</>,
    },
    {
      title: t('SESSION_DATE'),
      dataIndex: 'sessionDates',
      key: 'sessionDates',
      ellipsis: true,
      render: sessionDates => <SessionDates sessionDates={sessionDates} />,
    },
    {
      title: t('TAG'),
      dataIndex: 'tags',
      key: 'tagIds',
      filters: [],
      filterSearch: true,
      width: 50,
      onCell: () => ({
        className: 'custom-cell',
        onClick: e => {
          e.stopPropagation();
        },
      }),
      render: (text, record) => (
        <Tags
          selectedTags={record.tags || []}
          associationId={record.registrantId}
          url={ETagsUrls.REGISTRANT}
          invalidateTagsConfig={[
            { invalidateTag: 'family', sliceName: familyApi },
          ]}
          tagType={ETagType.REGISTRANT}
        />
      ),
    },
    {
      title: ' ',
      key: 'actions',
      dataIndex: 'actions',
      fixed: 'right',
      width: 60,
      render: (text, record) => <FamilyListActions familyData={record} />,
      onCell: () => ({
        onClick: event => {
          event.stopPropagation();
        },
      }),
    },
  ];

  return { familyColumns };
};

export default useGetFamilyColumnsData;
