import React, { FC } from 'react';
import { Button, Col, Icon } from '@idaho-aeyc/ui-kit';
import { TActionButtonProps } from './ActionButton.types';

export const ActionButton: FC<TActionButtonProps> = ({
  icon,
  tooltipTitle,
  onClick,
}) => {
  return (
    <Col>
      <Button
        icon={<Icon alt="icon" icon={icon} size={14} className="pointer" />}
        tooltip={tooltipTitle}
        onClick={onClick}
        color="primary"
        shape="circle"
        size="middle"
      />
    </Col>
  );
};
