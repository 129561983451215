import React, { FC, useRef, useState } from 'react';
import { Col, EIconNames, Row, Spinner } from '@idaho-aeyc/ui-kit';
import { TEditableContentWithActionsProps } from './EditableContentWithActions.types';
import { ActionButton } from './ActionButton';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { useTranslation } from '../../hooks';

export const EditableContentWithActions: FC<
  TEditableContentWithActionsProps
> = ({
  onSave,
  onCancel,
  onEdit,
  readView,
  editView,
  wrapperClassName,
  wrapperSytle,
  isLoading,
  isReadOnly,
}) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.workshops.details.child-care.table.actions',
  });
  const [isEditView, setIsEditView] = useState<boolean>(false);
  const onSaveClick = async () => {
    await onSave();
    setIsEditView(false);
  };
  const onCancelClick = () => {
    setIsEditView(false);
    if (onCancel) onCancel();
  };

  const onEditClick = () => {
    setIsEditView(true);
    if (onEdit) onEdit();
  };

  const wrapperRef = useRef(null);

  useOutsideClick(wrapperRef, onSaveClick, isEditView);

  return (
    <Spinner spinning={!!isLoading}>
      <Row
        style={wrapperSytle}
        className={wrapperClassName}
        justify="space-between"
        align="middle"
        wrap={false}
        ref={wrapperRef}
      >
        <Col>{isEditView ? editView : readView}</Col>
        {!isReadOnly && (
          <Col>
            {isEditView ? (
              <Row gutter={[8, 8]} wrap={false}>
                <ActionButton
                  icon={EIconNames.CHECK}
                  tooltipTitle={t('SAVE')}
                  onClick={onSaveClick}
                />
                <ActionButton
                  icon={EIconNames.CLOSE}
                  tooltipTitle={t('CANCEL')}
                  onClick={onCancelClick}
                />
              </Row>
            ) : (
              <ActionButton
                icon={EIconNames.EDIT_FILLED}
                tooltipTitle={t('EDIT')}
                onClick={onEditClick}
              />
            )}
          </Col>
        )}
      </Row>
    </Spinner>
  );
};
