import { EWorkshopChildcareAgeGroups } from '../../../data/types';
import { TChildcareItem } from '../ProvidingChildcare.types';

export enum EEditChildcareReducerActions {
  CHECKBOX_CHANGE = 'CHECKBOX_CHANGE',
  COUNTER_CHANGE = 'COUNTER_CHANGE',
  RESET = 'RESET',
}

export const initialState: TChildcareItem[] = Object.values(
  EWorkshopChildcareAgeGroups,
).map(item => ({
  name: item,
  checked: false,
  value: 0,
}));

export type TAction =
  | {
      type: EEditChildcareReducerActions.CHECKBOX_CHANGE;
      name: EWorkshopChildcareAgeGroups;
      checked: boolean;
    }
  | {
      type: EEditChildcareReducerActions.COUNTER_CHANGE;
      name: EWorkshopChildcareAgeGroups;
      value: number;
    }
  | { type: EEditChildcareReducerActions.RESET };

export const reducer = (
  state: TChildcareItem[],
  action: TAction,
): TChildcareItem[] => {
  switch (action.type) {
    case EEditChildcareReducerActions.CHECKBOX_CHANGE:
      return state.map(item => {
        if (item.name === action.name) {
          return {
            ...item,
            checked: action.checked,
            value: action.checked ? 1 : 0,
          };
        }
        return item;
      });
    case EEditChildcareReducerActions.COUNTER_CHANGE:
      return state.map(item => {
        if (item.name === action.name) {
          return {
            ...item,
            value: action.value,
          };
        }
        return item;
      });
    case EEditChildcareReducerActions.RESET:
      return initialState;
    default:
      return state;
  }
};
