import { FC } from 'react';
import { useTranslation, Col, Row } from '@packages/utils';
import { EAttendanceType } from 'data/types/workshops.types';
import Host from './Host';
import Location from './Location';
import { TBasicInfoProps } from './BasicInfo.types';
import Providing from './Providing';
import ShippingAddress from './ShippingAddress';
import WorkshopPhoto from '../WorkshopPhoto';
import CoordinatorInput from './CoordinatorInput';
import WorkshopCapacity from './WorkshopCapacity';
import MeetingLink from './MeetingLink';
import BadgeWrapper from './BadgeWrapper';
import PartsDate from './PartsDates';

const BasicInfo: FC<TBasicInfoProps> = ({
  workshop,
  id,
  isFetchingWorkshopData,
}) => {
  const { t } = useTranslation();
  const isVirtual = workshop?.attendanceType === EAttendanceType.VIRTUAL;
  return (
    <BadgeWrapper
      badgeItems={[
        {
          text: t(
            'descriptions.admin-portal.workshops.details.basic-info.PRIVATE',
          ),
          hide: !workshop?.hostedPrivately,
          color: 'primary',
        },
        {
          text: t(
            'descriptions.admin-portal.workshops.details.basic-info.VIRTUAL_WORKSHOP',
          ),
          hide: !isVirtual,
          color: 'purple',
        },
      ]}
    >
      <Row gutter={[16, 0]}>
        <Col flex="0 1 300px">
          <WorkshopPhoto photo={workshop?.photo} />
        </Col>
        <Col flex="1 1">
          <Row gutter={[0, 16]}>
            <Col span={24}>
              <Host id={id} host={workshop?.host} />
            </Col>

            <Col span={24}>
              {isVirtual ? (
                <MeetingLink
                  workshopId={id}
                  meetingLink={workshop?.meetingLink}
                />
              ) : (
                <Location
                  location={workshop?.location}
                  host={workshop?.host}
                  sameAsHost={workshop?.locationSameAsHost}
                  id={id}
                />
              )}
            </Col>

            {!isVirtual && (
              <Col span={24}>
                <ShippingAddress
                  shippingAddress={workshop?.shipmentAddress}
                  sameAsLocation={
                    workshop?.shipmentAddressSameAsLocationAddress
                  }
                  locationAddressId={workshop?.location?.address?.id}
                  id={id}
                  isFetchingWorkshopData={isFetchingWorkshopData}
                />
              </Col>
            )}
            <Col span={24}>
              <PartsDate
                id={id}
                parts={workshop?.parts}
                workshopStatus={workshop?.status}
              />
            </Col>
            <Col span={24}>
              <CoordinatorInput
                id={id}
                coordinators={workshop?.siteCoordinators}
              />
            </Col>
            <Col span={24}>
              <WorkshopCapacity
                workshopStatus={workshop?.status}
                workshopId={id}
                capacity={workshop?.capacity}
              />
            </Col>
            {!isVirtual && (
              <Col span={24}>
                <Providing
                  childcareProvided={workshop?.childcareProvided}
                  foodProvided={workshop?.foodProvided}
                  id={id}
                  workshopStatus={workshop?.status}
                />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </BadgeWrapper>
  );
};

export default BasicInfo;
