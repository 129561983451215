import { TCreateConverter } from '@packages/utils';
import { TFetchConverter } from 'data/types/converter.types';
import {
  TTagDB,
  TTagUI,
  TTagsEditDB,
  TTagsEditUI,
} from 'data/types/tags.types';

export const tagsGetConverter: TFetchConverter<TTagUI[], TTagDB[] | undefined> =
  {
    fromDb: tags =>
      (tags || []).map(tag => ({
        value: tag.id,
        text: tag.name,
      })),
  };

export const tagsEditConverter: TCreateConverter<TTagsEditUI, TTagsEditDB> = {
  toDb: data => ({
    associationId: data.associationId,
    assignedTagIds: data.assignedTagIds,
    removedTagIds: data.removedTagIds,
    workshopSubscriberId: data.workshopSubscriberId,
  }),
};
