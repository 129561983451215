import React, { FC, useEffect, useReducer } from 'react';
import { TCheckboxChangeEvent, Col } from '@idaho-aeyc/ui-kit';
import EditChildcareItem from './EditChildcareItem';
import { TEditChildcareProps } from './EditChildcare.types';
import {
  reducer,
  initialState,
  EEditChildcareReducerActions,
} from './childcare.reducer';
import { EWorkshopChildcareAgeGroups } from '../../../data/types';
import { SEditChildcareWrapper } from '../ProvidingChildcare.styles';

export const EditChildcare: FC<TEditChildcareProps> = ({
  initialChildcare,
  onChange,
  translationLanguage,
  width,
}) => {
  const [childcare, dispatch] = useReducer(
    reducer,
    initialChildcare || initialState,
  );
  const onCheckboxChange = (
    e: TCheckboxChangeEvent,
    name: EWorkshopChildcareAgeGroups,
  ) => {
    const { checked } = e.target;
    dispatch({
      type: EEditChildcareReducerActions.CHECKBOX_CHANGE,
      name,
      checked,
    });
  };

  const onCounterChange = (
    value: number,
    name: EWorkshopChildcareAgeGroups,
  ) => {
    dispatch({
      type: EEditChildcareReducerActions.COUNTER_CHANGE,
      name,
      value,
    });
  };

  useEffect(() => {
    if (onChange) {
      onChange(childcare);
    }
  }, [childcare, onChange]);
  // max with is modal width
  return (
    <SEditChildcareWrapper gutter={[0, 4]} width={width}>
      {childcare.map(item => (
        <Col key={item.name} span={24}>
          <EditChildcareItem
            childcareItem={item}
            onCheckboxChange={value => onCheckboxChange(value, item.name)}
            counterOnChange={value => onCounterChange(value, item.name)}
            translationLanguage={translationLanguage}
          />
        </Col>
      ))}
    </SEditChildcareWrapper>
  );
};
