import { useTranslation, Tag } from '@packages/utils';
import { Row, Col } from 'components/Base/Grid';
import { TColumnType } from 'components/Base/Table/Table.types';
import Tags from 'components/Shared/Tags';
import useGetTableColumnData from 'components/Base/Table/components/SearchFilter';
import { CopyTextButton } from 'components/Base/CopyTextButton';
import AgeGroupesColumn from 'components/Shared/AgeGroupesColumn';
import { EAgeGroup } from 'data/types/generic.types';
import {
  EWorkshopSubscribersColumnKey,
  TWorkshopSubscriberDataUI,
} from 'data/types/subscribers.types.';
import { ETagType } from 'data/types/tags.types';
import { ETagsUrls } from 'data/urls/tags.url';
import { subscibersApi } from 'redux/apiSlice/subscibers.slice';
import UnsubscribeAction from './Actions/UnsubscribeAction';

const useGetColumnsData = () => {
  const { getTableColumnSearchProps } = useGetTableColumnData();
  const { t } = useTranslation();
  const defaultColumns: TColumnType<TWorkshopSubscriberDataUI>[] = [
    {
      title: t(
        'descriptions.admin-portal.subscribers.list.table.title.REGISTRATION_DATE',
      ),
      dataIndex: 'createdDate',
      key: 'createdDate',
    },
    {
      title: t(
        'descriptions.admin-portal.subscribers.list.table.title.FIRST_NAME',
      ),
      dataIndex: 'firstName',
      sorter: true,
      key: EWorkshopSubscribersColumnKey.FIRST_NAME,
      ...getTableColumnSearchProps<TWorkshopSubscriberDataUI>(),
    },
    {
      title: t(
        'descriptions.admin-portal.subscribers.list.table.title.LAST_NAME',
      ),
      dataIndex: 'lastName',
      sorter: true,
      key: EWorkshopSubscribersColumnKey.LAST_NAME,
      ...getTableColumnSearchProps<TWorkshopSubscriberDataUI>(),
    },
    {
      title: t('descriptions.admin-portal.subscribers.list.table.title.MOBILE'),
      dataIndex: 'phone',
      key: 'phone',
      ellipsis: true,
    },
    {
      title: t('descriptions.admin-portal.subscribers.list.table.title.EMAIL'),
      dataIndex: 'email',
      onCell: () => ({
        className: 'custom-cell',
        onClick: e => {
          e.stopPropagation();
        },
      }),
      render: (text, record) => {
        return <CopyTextButton title={record.email} />;
      },
      key: EWorkshopSubscribersColumnKey.EMAIL,
      ...getTableColumnSearchProps<TWorkshopSubscriberDataUI>(),
    },
    {
      title: t(
        'descriptions.admin-portal.subscribers.list.table.title.AGE_GROUP',
      ),
      dataIndex: 'ageGroup',
      key: EWorkshopSubscribersColumnKey.AGE_GROUP,
      filters: Object.values(EAgeGroup)
        .filter(
          ageGroup =>
            ageGroup !== EAgeGroup.THREE_TO_FIVE &&
            ageGroup !== EAgeGroup.TWO_TO_FIVE,
        )
        .map(ageGroup => ({
          text: t(`enums.age-group.${ageGroup}`),
          value: ageGroup,
        })),
      render: (_, { englishAgeGroups, spanishAgeGroups }) => {
        return (
          <AgeGroupesColumn
            englishSessions={englishAgeGroups}
            spanishSessions={spanishAgeGroups}
          />
        );
      },
    },
    {
      title: t('descriptions.admin-portal.subscribers.list.table.title.CITY'),
      dataIndex: 'cities',
      key: EWorkshopSubscribersColumnKey.CITY,
      filters: [],
      filterSearch: true,
      width: 200,
      render: cities => (
        <Row wrap>
          {cities.map((city: string) => {
            return (
              <Col key={city}>
                <Tag color="white">{city}</Tag>
              </Col>
            );
          })}
        </Row>
      ),
    },
    {
      title: t('descriptions.admin-portal.subscribers.list.table.title.TAG'),
      dataIndex: 'tags',
      key: EWorkshopSubscribersColumnKey.TAG,
      filters: [],
      width: 50,
      fixed: 'right',
      filterSearch: true,
      onCell: () => ({
        className: 'custom-cell',
        onClick: e => {
          e.stopPropagation();
        },
      }),
      render: (text, record) => (
        <Tags
          selectedTags={record.tags || []}
          associationId={record.workshopSubscriberId}
          url={ETagsUrls.SUBSCRIBER}
          invalidateTagsConfig={[
            {
              invalidateTag: 'workshop-subscribers',
              sliceName: subscibersApi,
            },
          ]}
          tagType={ETagType.SUBSCRIBER}
          isWorkshopSubscribers
        />
      ),
    },
    {
      title: ' ',
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      width: 50,
      render: (text, record) => (
        <UnsubscribeAction id={record.workshopSubscriberId} />
      ),
    },
  ];

  return { defaultColumns };
};

export default useGetColumnsData;
